import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!

  return (_ctx.supplementalContent)
    ? (_openBlock(), _createBlock(_component_sc_rich_text, {
        key: 0,
        class: "vlp-information-container",
        field: _ctx.supplementalContent
      }, null, 8, ["field"]))
    : _createCommentVNode("", true)
}