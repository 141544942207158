import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icons/Path_filtermain.svg'


const _hoisted_1 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_sc_text = _resolveComponent("sc-text")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_sc_link = _resolveComponent("sc-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['cta-banner d-flex', _ctx.isGridClass])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.imageClass)
    }, [
      _createVNode(_component_sc_image, {
        media: _ctx.fields.Image
      }, null, 8, ["media"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.containerClass)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.infoClass)
      }, [
        _createElementVNode("h3", null, [
          _createVNode(_component_sc_text, {
            field: _ctx.fields.Heading
          }, null, 8, ["field"])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.bodyClass)
        }, [
          _createVNode(_component_sc_rich_text, {
            field: _ctx.fields.Body
          }, null, 8, ["field"])
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.buttonClass)
      }, [
        (!_ctx.isGrid)
          ? (_openBlock(), _createBlock(_component_sc_link, {
              key: 0,
              "data-testid": "cta-banner-link",
              class: "btn btn-secondaryDark",
              field: _ctx.fields.Link
            }, null, 8, ["field"]))
          : _createCommentVNode("", true),
        (_ctx.isGrid)
          ? (_openBlock(), _createBlock(_component_sc_link, {
              key: 1,
              "data-testid": "cta-tile-link",
              class: "cta-link-grid",
              field: _ctx.fields.Link
            }, null, 8, ["field"]))
          : _createCommentVNode("", true),
        (_ctx.isGrid)
          ? (_openBlock(), _createElementBlock("img", {
              key: 2,
              class: "cta-link-grid arrow-icon-grid",
              src: _imports_0,
              alt: _ctx.$t('srp.common.rightArrowIcon')
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ], 2))
}