import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "day-and-time"
}
const _hoisted_2 = {
  id: "day-picker",
  class: "day-picker"
}
const _hoisted_3 = {
  class: "h6",
  "data-testid": "test-drive-dayLabel"
}
const _hoisted_4 = {
  class: "day-toggle-group",
  "data-testid": "test-drive-dayGroup"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  id: "time-picker",
  class: "time-picker"
}
const _hoisted_7 = { class: "time-picker-heading" }
const _hoisted_8 = {
  class: "h6",
  "data-testid": "test-drive-timeLabel"
}
const _hoisted_9 = {
  key: 0,
  class: "busy-label"
}
const _hoisted_10 = {
  class: "time-toggle-group",
  "data-testid": "test-drive-timeGroup"
}
const _hoisted_11 = { class: "button-and-error" }
const _hoisted_12 = {
  key: 0,
  class: "v-messages__message"
}
const _hoisted_13 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_ctx.view === 'dayAndTime')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.fields?.dayLabel?.value), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appointments, (date) => {
              return (_openBlock(), _createBlock(_component_v_btn_toggle, {
                modelValue: _ctx.selectedDay,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDay) = $event)),
                key: date
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    onClick: ($event: any) => (_ctx.setDay(date)),
                    onKeyup: _withKeys(($event: any) => (_ctx.setDay(date)), ["enter"]),
                    class: _normalizeClass(_ctx.selectedDay === date ? 'active' : '')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        innerHTML: _ctx.getDayString(date.day)
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "onKeyup", "class"])
                ]),
                _: 2
              }, 1032, ["modelValue"]))
            }), 128))
          ])
        ]),
        (_ctx.selectedDay.day)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.fields.timeLabel.value), 1),
                (_ctx.fields.showUsuallyBusy.value)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                      _createVNode(_component_v_icon, { icon: "ep-icon-time" }),
                      _createTextVNode(_toDisplayString(_ctx.fields.usuallyBusyLabel.value), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedDay.times, (timeSlot) => {
                  return (_openBlock(), _createBlock(_component_v_btn_toggle, {
                    modelValue: _ctx.selectedTime,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTime) = $event)),
                    key: timeSlot
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: ($event: any) => (_ctx.setTime(timeSlot.time)),
                        onKeyup: _withKeys(($event: any) => (_ctx.setTime(timeSlot.time)), ["enter"]),
                        class: _normalizeClass(_ctx.selectedTime === timeSlot.time ? 'active' : '')
                      }, {
                        default: _withCtx(() => [
                          (timeSlot.usuallyBusy && _ctx.fields.showUsuallyBusy.value)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 0,
                                icon: "ep-icon-time"
                              }))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(timeSlot.time), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "onKeyup", "class"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue"]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedDayAndTime)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              id: "continue-button",
              class: "continue-button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setDayAndTime && _ctx.setDayAndTime(...args))),
              onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.setDayAndTime && _ctx.setDayAndTime(...args)), ["enter"]))
            }, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_v_btn, {
                  loading: _ctx.loading,
                  "data-testid": "test-drive-dayAndTimeContinueButton"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.fields.dayTimePageContinueButtonText.value), 1)
                  ]),
                  _: 1
                }, 8, ["loading"]),
                (_ctx.showErrorMessage)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_12, " Please select a time before proceeding. "))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.fields.showUsuallyBusy.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.fields.usuallyBusyNote.value), 1))
                : _createCommentVNode("", true)
            ], 32))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}