<!-- NOTE [pg] Any reasons not to push this up into VehicleSearchContainer? -->
<template>
  <div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { mapState, mapMutations, mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { resolveUrl } from '@util/routeBuilder';
import { fetchSrpVehicles, fetchFacetCounts, scrollToTop } from '@util/searchControllerUtils';
import { arraysEqualShallow, deepEqual } from '@util/commonUtils';

export default defineComponent({
  name: 'SearchController',
  head() {
    this.applyMetaData();
    return this.headerData;
  },
  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapState('searchResults', {
      page: (state) => state.queryParams?.page,
      facetFilters: (state) => state.facetFilters || {},
      facets: (state) => state.srpVehiclesData?.facets || {},
    }),
    ...mapState('common', ['zipCode', 'urlCount', 'headerData']),
    ...mapState('searchResults', [
      'shopByStore',
      'shipToStore',
      'shipToStoreRadius',
      'storeId',
      'sortType',
      'includedBodyStyles',
      'includedDealerships',
      'queryParams',
      'defaultRadius',
      'radius',
      'remainingParams',
      'take',
    ]),
    ...mapGetters('searchResults', ['metaDataContent', 'metaDataLocation', 'vehicleCount']),
    urlProperties() {
      return {
        facetFilters: this.facetFilters,
        queryParams: this.queryParams,
        includedDealerships: this.includedDealerships,
        storeId: this.storeId,
        shipToStore: this.shipToStore,
        radius: this.radius,
        sortType: this.sortType,
        remainingParams: this.remainingParams,
      };
    },
    itemPath() {
      return this.$jss?.sitecoreContext()?.itemPath;
    },
    layoutPath() {
      return this.$jss?.sitecoreContext()?.layoutPath;
    },
  },
  created() {},
  // NOTE: mounted is not called during SSR
  async mounted() {
    this.emitter.on('route-update', async () => {
      const sitecoreContext = this.$jss?.store?.state?.sitecoreContext;
      // If the user has made multiple selections, reduce the count (of "clicks")
      // until only 1 remains, don't resolve them yet
      if (this.urlCount > 1) {
        this.decrementUrlCount();
        // When there is one "click" left, resolve it by applying the sitecore context
        // that comes back from the pipeline, to overwrite the store with the "official" values
        // based on the most recent user selection
      } else if (this.urlCount === 1) {
        this.applySitecoreContext(sitecoreContext);
        this.decrementUrlCount();
      } else {
        // Else we got here from pressing the back button or from refreshing the page,
        // no "clicks" were involved, therefore we will apply the context from the pipeline
        // to overwrite the store with the official values, AND potentially need to make
        // an API call to fetch new vehicles based on those values if they are different
        // than what's in the store (the other two options make the api call when clicks are made
        // in the application)
        let stateChanged = this.applySitecoreContext(sitecoreContext);
        if (stateChanged) {
          await this.fetchSrpVehicles();
        }
      }
    });

    this.emitter.on('fetch-facet-count', async (facetToRemove) => {
      await fetchFacetCounts(this.$store, facetToRemove);
      this.emitter.emit('facet-count-fetched');
    });

    const handleUpdate = async () => {
      scrollToTop();
      this.setPage(0);
      await this.fetchSrpVehicles();
    };

    /* Carry-over from vue2 - multiple events triggering the same logic */
    this.emitter.on('fetch-cars-srp', handleUpdate);
    this.emitter.on('filter-updated-srp', handleUpdate);
    this.emitter.on('sort-updated-srp', handleUpdate);

    this.emitter.on('pagination-fetch-srp', async (_args) => {
      scrollToTop();
      await this.fetchSrpVehicles();
    });

    // set this to avoid watcher not triggering on page load with preset filters NP 7/1/2024
    const currentFacetFilters = this.facetFilters;
    this.setFacetFilters(currentFacetFilters);

    this.emitter.emit('route-update');
  },
  methods: {
    ...mapMutations('common', ['decrementUrlCount', 'setHeaderData']),
    ...mapMutations('searchResults', [
      'setPage',
      'setTake',
      'setShipToStore',
      'setShipToStoreRadius',
      'setFacetFilters',
      'setIncludedDealerships',
      'initializeRadius',
      'setSortType',
      'setRadius',
      'setRemainingParams',
      'setSupplementalContent',
    ]),
    ...mapMutations('common', ['initializeZip']),
    resolveUrl() {
      const params = {
        facetFilters: this.facetFilters,
        queryParams: this.queryParams,
        includedDealerships: this.includedDealerships,
        ship: this.shipToStore,
        sortType: this.sortType,
        itemPath: this.itemPath,
        layoutPath: this.layoutPath,
        radius: this.radius,
        defaultRadius: this.defaultRadius,
        shopByStore: this.shopByStore,
        remainingParams: this.remainingParams,
        root: this.$root,
      };

      resolveUrl(this.$store, this.$router, params);
    },
    applyMetaData() {
      const srpHeaderData = this.headerData;
      srpHeaderData.title = this.pageTitle();
      srpHeaderData.meta.find((m) => m.name == 'description').content = this.pageDescription();
      this.setHeaderData(srpHeaderData); // save into the vuex state -> head() is reactive to that
    },
    applySitecoreContext(context) {
      let stateChanged = false;
      const contextZip = context?.location?.zip;
      const contextTake = context?.appliedFacetFilters?.take;
      const contextFacetFilters = context?.appliedFacetFilters?.facetFilters;
      const contextPage = context?.appliedFacetFilters?.page ?? 0;
      const contextShip = context?.appliedFacetFilters?.ship ?? true;
      const contextDealerships = context?.appliedFacetFilters?.dealers ?? [];
      const contextRadius = context?.appliedFacetFilters?.radius;
      const contextSort = context?.appliedFacetFilters?.sort ?? {
        sortBy: 'distance',
        sortDirection: 'asc',
        sortName: 'Distance: Nearest',
      };

      this.applyMetaData(); // Set the header/description and other SEO meta data

      // Update the supplemental content, i.e. custom content added for specific search pages
      const contextSupplementalContent = context?.supplementalContentItem?.Content ?? null;
      this.setSupplementalContent(contextSupplementalContent);

      /* This adds anything from the querystring that is not processed into a valid param 
      (anything that isnt ship, sort, etc) */
      const contextRemainingParams = context?.appliedFacetFilters?.remainingParams ?? {};
      this.setRemainingParams(contextRemainingParams);

      const checkAndUpdate = (contextValue, currentStateValue, updateFunction) => {
        if (contextValue !== undefined && contextValue !== currentStateValue) {
          stateChanged = true;
          updateFunction(contextValue);
        }
      };

      checkAndUpdate(contextZip, this.zipCode, this.initializeZip.bind(this));
      checkAndUpdate(contextPage, this.page, this.setPage.bind(this));
      checkAndUpdate(contextTake, this.take, this.setTake.bind(this));
      checkAndUpdate(contextShip, this.shipToStore, (value) => {
        this.setShipToStore(value);
        this.setShipToStoreRadius(value);
      });
      checkAndUpdate(contextRadius, this.radius, this.initializeRadius.bind(this));

      if (this.radius === null) {
        stateChanged = true;
        this.setRadius(this.defaultRadius);
      }

      if (!deepEqual(contextSort, this.sortType)) {
        stateChanged = true;
        this.setSortType(contextSort);
      }

      if (!deepEqual(contextFacetFilters, this.facetFilters)) {
        stateChanged = true;
        this.setFacetFilters(contextFacetFilters);
      }

      if (!arraysEqualShallow(contextDealerships, this.includedDealerships)) {
        stateChanged = true;
        this.setIncludedDealerships(contextDealerships);

        if (contextDealerships.length === 0) {
          if (this.shipToStoreRadius !== contextShip) {
            this.setShipToStore(this.shipToStoreRadius);
            this.setShipToStoreRadius(this.shipToStoreRadius);
          }
        }
      }

      return stateChanged;
    },
    async fetchSrpVehicles() {
      await fetchSrpVehicles(this.$store, this);
    },
    pageTitle() {
      const content = this.$jss?.routeData();
      const defaultResult = content?.fields?.pageTitle?.value ?? 'Used cars | EchoPark ';
      // seoItem will be empty UNLESS an override has been added in Sitecore (usually MMT)
      const seoItem = this.$jss?.store?.state?.sitecoreContext?.seoItem;
      if (seoItem && seoItem['Meta Title']?.value !== undefined) {
        return seoItem['Meta Title'].value;
      }
      if (this.metaDataContent && this.metaDataLocation) {
        return (
          this.$t('SrpMetaTitleWithLocation')
            .replace('##facets##', this.metaDataContent)
            .replace('##location##', this.metaDataLocation) ?? defaultResult
        );
      }
      if (this.metaDataContent) {
        return this.$t('SrpMetaTitle').replace('##facets##', this.metaDataContent) ?? defaultResult;
      }

      return defaultResult;
    },
    pageDescription() {
      const content = this.$jss?.routeData();
      // seoItem will be empty UNLESS an override has been added in Sitecore (usually MMT)
      const seoItem = this.$jss?.store?.state?.sitecoreContext?.seoItem;
      if (seoItem && seoItem['Meta Description']?.value !== undefined) {
        return seoItem['Meta Description'].value;
      } else if (this.metaDataContent && this.metaDataLocation) {
        return this.$t('SrpMetaDescriptionWithLocation')
          .replace('##facets##', this.metaDataContent)
          .replace('##location##', this.metaDataLocation);
      } else if (this.metaDataContent) {
        return this.$t('SrpMetaDescription').replace('##facets##', this.metaDataContent);
      } else if (content?.fields?.['Meta Description']?.value) {
        return content.fields['Meta Description'].value;
      } else if (!this.metaDataContent) {
        return this.$t('SrpMetaDefaultDescription').replace('##count##', this.vehicleCount);
      } else {
        return null;
      }
    },
  },
  watch: {
    urlProperties: {
      handler() {
        this.resolveUrl();
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss"></style>
