<template>
  <div :class="['cta-banner d-flex', isGridClass]">
    <div :class="imageClass">
      <sc-image :media="fields.Image" />
    </div>
    <div :class="containerClass">
      <div :class="infoClass">
        <h3><sc-text :field="fields.Heading" /></h3>
        <div :class="bodyClass">
          <sc-rich-text :field="fields.Body" />
        </div>
      </div>
      <div :class="buttonClass">
        <sc-link
          v-if="!isGrid"
          data-testid="cta-banner-link"
          class="btn btn-secondaryDark"
          :field="fields.Link"
        />
        <sc-link
          v-if="isGrid"
          data-testid="cta-tile-link"
          class="cta-link-grid"
          :field="fields.Link"
        />
        <img
          v-if="isGrid"
          class="cta-link-grid arrow-icon-grid"
          src="@icons/Path_filtermain.svg"
          :alt="$t('srp.common.rightArrowIcon')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { Image, RichText, Text, Link } from '@sitecore-jss/sitecore-jss-vue';

export default defineComponent({
  name: 'CtaCarOffer',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    isGrid: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ScImage: Image,
    ScRichText: RichText,
    ScText: Text,
    ScLink: Link,
  },
  setup(props) {
    const isGridClass = computed(() => (props.isGrid ? 'cta-banner-grid' : ''));
    const imageClass = computed(() => (props.isGrid ? 'cta-banner-img-grid' : 'cta-banner-img'));
    const containerClass = computed(() =>
      props.isGrid ? 'cta-banner-container-grid' : 'cta-banner-container d-flex'
    );
    const infoClass = computed(() => (props.isGrid ? 'cta-banner-info-grid' : 'cta-banner-info'));
    const bodyClass = computed(() => (props.isGrid ? 'cta-banner-body-grid' : 'cta-banner-body'));
    const buttonClass = computed(() => (props.isGrid ? 'cta-banner-btn-grid' : 'cta-banner-btn'));

    return {
      isGridClass,
      imageClass,
      containerClass,
      infoClass,
      bodyClass,
      buttonClass,
    };
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.cta-banner {
  border: 1px solid var(--park-gray-90);
  border-radius: 16px;
  background-color: #ffffff;
  max-width: 420px;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    max-width: unset;
  }

  &.cta-banner-grid {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
  }
}

.cta-banner-img,
.cta-banner-img-grid {
  img {
    height: 100px;
    width: 100px;
  }

  &.cta-banner-img {
    margin: 10px 20px 10px 10px;
  }

  &.cta-banner-img-grid {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cta-banner-container,
.cta-banner-container-grid {
  padding: 16px 24px 16px 0;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: unset;
  }
}

.cta-banner-info,
.cta-banner-info-grid {
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--park-gray-10);
    margin: 0;
  }

  &.cta-banner-info {
    max-width: 500px;
    margin-right: 10px;
  }

  &.cta-banner-info-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
}

.cta-banner-body,
.cta-banner-body-grid {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--park-gray-10);

  &.cta-banner-body-grid {
    text-align: center;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.cta-banner-btn,
.cta-banner-btn-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;

  @include media-breakpoint-up(sm) {
    margin: 0 0 0 auto;
  }

  .btn {
    border-width: 2px;
    border-style: solid;

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }

  .btn-secondaryDark {
    &,
    &:hover {
      background-color: transparent;
      color: var(--park-gray-0);
      border-color: var(--park-gray-10);
    }
  }

  &.cta-banner-btn-grid {
    margin-top: 20px;
    width: 100%;
  }
}

.cta-link-grid {
  color: #006fa6;
  padding-right: 10px;
  font-weight: 600;
  font-size: 14px;
}

.arrow-icon-grid {
  filter: brightness(0) saturate(100%) invert(18%) sepia(91%) saturate(3047%) hue-rotate(184deg)
    brightness(99%) contrast(102%);
  height: 10px;
}
</style>
