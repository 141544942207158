<template>
  <div v-show="dynamicLinks.length" class="dynamicLinkArea">
    <TextButtonCarousel
      class="dynamicLinkItem"
      v-for="(list, key) in dynamicLinks"
      :title="list.title"
      :textButtonList="list.links"
      :carouselId="list.id"
      :key="`link-carousel-${key}`"
    />
  </div>
</template>

<script lang="ts">
import TextButtonCarousel from '@components-vue/Foundation/Common/Buttons/TextButtonCarousel.vue';
import { mapState, mapMutations } from 'vuex';
import { facetMetaData } from '@util/facetHelper';
import { defineComponent } from 'vue';
import { DynamicLink } from '@/types/VehicleSearch/SearchResults';

export default defineComponent({
  name: 'DynamicLinks',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    TextButtonCarousel,
  },
  computed: {
    ...mapState('searchResults', ['srpVehiclesData', 'facetFilters']),
    dynamicLinks(): DynamicLink[] {
      const appState = this.$jss?.sitecoreContext();
      if (!appState) return [];

      const baseUrl = appState.canonicalUrl?.split(appState.itemPath)[0];
      const srpUrl = `${baseUrl}${appState?.itemPath}`;
      const isSeoItem = !!appState?.seoItem;
      const seoMetaTitle = appState?.locationDisplayName;

      if (this.srpVehiclesData && this.facetFilters) {
        return [
          ...this.buildLinksWithoutFacets(this.facetFilters, srpUrl, isSeoItem, seoMetaTitle),
          ...this.buildLinksMmt(this.facetFilters, srpUrl, isSeoItem, seoMetaTitle),
          ...this.buildLinksBodystyle(this.facetFilters, srpUrl, isSeoItem, seoMetaTitle),
          ...this.buildLinksBodystyleMake(this.facetFilters, srpUrl, isSeoItem, seoMetaTitle),
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapMutations('searchResults', ['setHierarchicalFacetFilter', 'setStringFacetFilter']),
    buildLinksWithoutFacets(
      facetFilters,
      srpUrl: string,
      isSeoItem: boolean,
      seoMetaTitle: string
    ) {
      const links: DynamicLink[] = [];
      // This is the default 'no filters selected' scenario
      if (!facetFilters || Object.keys(facetFilters).length === 0) {
        if (
          this.fields?.Type?.value === 'make' &&
          this.srpVehiclesData?.facets?.[facetMetaData.mmt.key] &&
          Object.keys(this.srpVehiclesData?.facets?.[facetMetaData.mmt.key] ?? {}).length > 0
        ) {
          links.push({
            id: facetMetaData.mmt.key,
            title:
              isSeoItem && seoMetaTitle
                ? this.fields?.SeoTitle?.value
                    .replace('##seoTitle##', seoMetaTitle)
                    .replace('##bodyStyle##', 'Cars')
                : this.fields?.DefaultTitle?.value.replace('##bodyStyle##', 'Cars'),
            links: Object.keys(this.srpVehiclesData.facets[facetMetaData.mmt.key]).map((x) => {
              return {
                text:
                  isSeoItem && seoMetaTitle
                    ? this.fields?.SeoLinkText?.value
                        .replace('##seoTitle##', seoMetaTitle)
                        .replace('##make##', `${x}s`)
                    : this.fields?.DefaultLinkText?.value.replace('##make##', `${x}s`),
                link: `${srpUrl}/${x.replace(' ', '-')}`,
              };
            }),
          });
        }

        if (
          this.fields?.Type?.value == 'bodystyle' &&
          this.srpVehiclesData.facets[facetMetaData.bodystyle.key]
        ) {
          links.push({
            id: facetMetaData.bodystyle.key,
            title:
              isSeoItem && seoMetaTitle
                ? this.fields?.SeoTitle?.value
                    .replace('##seoTitle##', seoMetaTitle)
                    .replace('##make##', 'Cars')
                : this.fields?.DefaultTitle?.value.replace('##make##', 'Cars'),
            links: Object.keys(this.srpVehiclesData.facets[facetMetaData.bodystyle.key])
              .filter((x) => this.srpVehiclesData.facets[facetMetaData.bodystyle.key][x] > 0)
              .map((x) => {
                return {
                  text:
                    isSeoItem && seoMetaTitle
                      ? this.fields?.SeoLinkText?.value
                          .replace('##seoTitle##', seoMetaTitle)
                          .replace('##bodyStyle##', x)
                      : this.fields?.DefaultLinkText?.value.replace('##bodyStyle##', x),
                  link: `${srpUrl}/${x.replace(' / ', '-').replace(' ', '-')}`,
                };
              }),
          });
        }
      }
      return links;
    },
    buildLinksMmt(facetFilters, srpUrl: string, isSeoItem: boolean, seoMetaTitle: string) {
      const links: DynamicLink[] = [];

      if (
        Object.keys(facetFilters).length === 1 &&
        facetFilters[facetMetaData.mmt.key] &&
        Object.keys(facetFilters[facetMetaData.mmt.key].facetValue).length === 1
      ) {
        let selectedMake = Object.keys(facetFilters[facetMetaData.mmt.key].facetValue)[0];
        if (Object.keys(facetFilters[facetMetaData.mmt.key].facetValue[selectedMake]).length > 1) {
          // don't show any links when make+model are selected
          return [];
        }

        if (
          this.fields?.Type?.value === 'model' &&
          this.srpVehiclesData.facets[facetMetaData.model.key] &&
          Object.keys(this.srpVehiclesData.facets[facetMetaData.model.key]).length > 0
        ) {
          links.push({
            id: facetMetaData.model.key,
            title:
              isSeoItem && seoMetaTitle
                ? this.fields?.SeoTitle?.value
                    .replace('##seoTitle##', seoMetaTitle)
                    .replace('##make##', selectedMake)
                : this.fields?.DefaultTitle?.value.replace('##make##', selectedMake),
            links: Object.keys(this.srpVehiclesData.facets[facetMetaData.model.key])
              .filter((x) => this.srpVehiclesData.facets[facetMetaData.model.key][x] > 0)
              .map((x) => {
                return {
                  text:
                    isSeoItem && seoMetaTitle
                      ? this.fields?.SeoLinkText?.value
                          .replace('##seoTitle##', seoMetaTitle)
                          .replace('##model##', x)
                      : this.fields?.DefaultLinkText?.value.replace('##model##', x),
                  link: `${srpUrl}/${selectedMake.replace(' ', '-')}/${x}`,
                };
              }),
          });
        }

        if (
          this.fields?.Type?.value === 'bodystyle' &&
          this.srpVehiclesData.facets[facetMetaData.bodystyle.key] &&
          Object.values(this.srpVehiclesData.facets[facetMetaData.bodystyle.key]).some(
            (x: any) => x > 0
          )
        ) {
          links.push({
            id: facetMetaData.bodystyle.key,
            title:
              isSeoItem && seoMetaTitle
                ? this.fields?.SeoTitle?.value
                    .replace('##seoTitle##', seoMetaTitle)
                    .replace('##make##', `${selectedMake}s`)
                : this.fields?.DefaultTitle?.value.replace('##make##', `${selectedMake}s`),
            links: Object.keys(this.srpVehiclesData.facets[facetMetaData.bodystyle.key])
              .filter((x) => this.srpVehiclesData.facets[facetMetaData.bodystyle.key][x] > 0)
              .map((x) => {
                return {
                  text:
                    isSeoItem && seoMetaTitle
                      ? this.fields?.SeoLinkText?.value
                          .replace('##seoTitle##', seoMetaTitle)
                          .replace('##bodyStyle##', `${selectedMake} ${x}`)
                      : this.fields?.DefaultLinkText?.value.replace(
                          '##bodyStyle##',
                          `${selectedMake} ${x}`
                        ),
                  link: `${srpUrl}/${selectedMake.replace(' ', '-')}/${x
                    .replace(' / ', '-')
                    .replace(' ', '-')}`,
                };
              }),
          });
        }
      }
      return links;
    },
    buildLinksBodystyle(facetFilters, srpUrl: string, isSeoItem: boolean, seoMetaTitle: string) {
      const links: DynamicLink[] = [];

      if (
        Object.keys(facetFilters).length === 1 &&
        facetFilters[facetMetaData.bodystyle.key]?.facetValue?.length === 1
      ) {
        let selectedBodyStyle = facetFilters[facetMetaData.bodystyle.key].facetValue[0];

        if (
          this.fields?.Type?.value === 'make' &&
          this.srpVehiclesData.facets[facetMetaData.mmt.key]
        ) {
          links.push({
            id: facetMetaData.mmt.key,
            title:
              isSeoItem && seoMetaTitle
                ? this.fields?.SeoTitle?.value
                    .replace('##seoTitle##', seoMetaTitle)
                    .replace('##bodyStyle##', `${selectedBodyStyle}s`)
                : this.fields?.DefaultTitle?.value.replace(
                    '##bodyStyle##',
                    `${selectedBodyStyle}s`
                  ),
            links: Object.keys(this.srpVehiclesData.facets[facetMetaData.mmt.key]).map((x) => {
              return {
                text:
                  isSeoItem && seoMetaTitle
                    ? this.fields?.SeoLinkText?.value
                        .replace('##seoTitle##', seoMetaTitle)
                        .replace('##make##', `${x} ${selectedBodyStyle}s`)
                    : this.fields?.DefaultLinkText?.value.replace(
                        '##make##',
                        `${x} ${selectedBodyStyle}s`
                      ),
                link: `${srpUrl}/${selectedBodyStyle
                  .replace(' / ', '-')
                  .replace(' ', '-')}/${x.replace(' ', '-')}`,
              };
            }),
          });
        }
      }
      return links;
    },
    buildLinksBodystyleMake(
      facetFilters,
      srpUrl: string,
      isSeoItem: boolean,
      seoMetaTitle: string
    ) {
      const links: DynamicLink[] = [];
      // Handle scenario when bodystyle+make are selected, not more or less
      // "WHEN (1 only) MAKE & (1 only) BODY STYLE SELECTED - Then only one carousel should show - Used <Make> <body style>s by model (each item shown like this: Used Nissan Rogue, etc.)"

      if (
        this.fields?.Type?.value === 'make+bodystyle' &&
        Object.keys(facetFilters).length === 2 &&
        facetFilters[facetMetaData.mmt.key] &&
        Object.keys(facetFilters[facetMetaData.mmt.key].facetValue).length === 1 &&
        facetFilters[facetMetaData.bodystyle.key]?.facetValue?.length === 1
      ) {
        const selectedMake = Object.keys(facetFilters[facetMetaData.mmt.key].facetValue)[0];
        const selectedBodyStyle = facetFilters[facetMetaData.bodystyle.key].facetValue[0];

        const options = Object.keys(
          this.srpVehiclesData.facets[facetMetaData.mmt.key][selectedMake]
        );

        links.push({
          id: facetMetaData.mmt.key,
          title:
            isSeoItem && seoMetaTitle
              ? this.fields?.SeoTitle?.value
                  .replace('##seoTitle##', seoMetaTitle)
                  .replace('##make##', selectedMake)
                  .replace('##bodyStyle##', selectedBodyStyle)
              : this.fields?.DefaultTitle?.value
                  .replace('##make##', selectedMake)
                  .replace('##bodyStyle##', selectedBodyStyle),
          links: options.map((x) => {
            return {
              text:
                isSeoItem && seoMetaTitle
                  ? this.fields?.SeoLinkText?.value
                      .replace('##seoTitle##', seoMetaTitle)
                      .replace('##make##', `${x} ${selectedBodyStyle}s`)
                  : this.fields?.DefaultLinkText?.value.replace(
                      '##make##',
                      `${x} ${selectedBodyStyle}s`
                    ),
              link: `${srpUrl}/${selectedBodyStyle
                .replace(' / ', '-')
                .replace(' ', '-')}/${x.replace(' ', '-')}`,
            };
          }),
        });
      }
      return links;
    },
  },
});
</script>

<style lang="scss">
.dynamicLinkArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: unset;
  gap: 40px;
  padding: 14px 24px 10px;
  max-width: 100vw;

  @media only screen and (min-width: 1240px) {
    max-width: calc(100vw - 306px); // width of .filter-menu-container
  }
}

.dynamicLinkItem {
  width: 100%;
}
</style>
