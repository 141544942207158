<template>
  <div class="contact-info" v-if="view === 'contactInfo'">
    <div class="header-info">
      <rich-text
        data-testid="test-drive-contactHeading"
        tag="p"
        class="h6"
        :field="fields.contactHeading"
      />
      <sc-text
        data-testid="test-drive-contactDetails"
        tag="p"
        class="small"
        :field="fields.contactDetails"
      />
    </div>
    <div id="contact-form" class="contact-form">
      <v-form ref="form" v-model="valid" @submit.prevent>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="firstname"
              :rules="[rules.firstname]"
              :label="fields.firstNameLabel.value"
              name="firstname"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="lastname"
              :rules="[rules.lastname]"
              :label="fields.lastNameLabel.value"
              name="lastname"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="email"
              :rules="[rules.email]"
              :label="fields.emailLabel.value"
              name="email"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="mobile"
              :rules="[rules.mobile]"
              :label="`${fields.mobileLabel.value} ${fields.phoneNumberOptional.value ? '(Optional)' : ''}`"
              @input="mobileMask"
              name="mobile"
              maxlength="14"
              type="tel"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <div id="book-button" class="book-button">
              <v-btn
                type="submit"
                :loading="loading"
                @click="validateForm"
                @keyup.enter="valid && setContactInfo(firstname, lastname, email, mobile)"
                data-testid="test-drive-contactConfirmationButton"
                >{{ fields.contactButtonText.value }}</v-btn
              >
              <p v-if="showErrorMessage" class="v-messages__message">
                Review & complete the missing information.
              </p>
              <rich-text tag="p" :field="fields.contactButtonNote" />
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import RichText from '@components-vue/Foundation/Common/RichText';
import { submitForScheduleTestDriveToELeadCRM, formatDealerId } from '@/util/scheduleTestDriveUtil';
import { DateTime } from 'luxon';

export default defineComponent({
  name: 'ScheduleTestDrive--Contact',
  components: { RichText, ScText: Text },
  computed: {
    ...mapGetters('scheduleTestDrive', ['contact', 'day', 'time', 'view', 'vehicle', 'dealership']),
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      showErrorMessage: false,
      rules: {
        firstname: (value: string) => {
          const namePattern = /^[A-Za-z]+$/;
          if (!value) return 'Enter a first name.';
          if (!namePattern.test(value))
            return 'First name should not contain numbers or special characters.';
          return true;
        },
        lastname: (value: string) => {
          const namePattern = /^[A-Za-z]+$/;
          if (!value) return 'Enter a last name.';
          if (!namePattern.test(value))
            return 'Last name should not contain numbers or special characters.';
          return true;
        },
        email: (value: string) => {
          if (value && /.+@.+\..+/.test(value)) return true;
          return 'Enter a valid email address.';
        },
        mobile: (value: string) => {
          const isOptional = this.fields.phoneNumberOptional.value;
          const isValidLength = value?.length === 14;
          const isEmpty = value?.length === 0;

          if (isOptional) {
            if (isValidLength || isEmpty) {
              return true;
            }
          } else {
            if (isValidLength) {
              return true;
            }
          }

          return 'Enter a 10-digit mobile number.';
        },
      },
      firstname: '',
      lastname: '',
      email: '',
      mobile: '',
      valid: null,
    };
  },
  methods: {
    ...mapMutations('scheduleTestDrive', ['setTestDriveContact', 'setTestDriveView']),
    validateForm() {
      const form = this.$refs.form as any;
      form.validate();

      if (!this.valid) {
        this.showErrorMessage = true;
      } else {
        this.showErrorMessage = false;
        this.setContactInfo(this.firstname, this.lastname, this.email, this.mobile);
      }
    },
    setContactInfo(firstname: string, lastname: string, email: string, mobile: string) {
      this.setTestDriveContact({
        first: firstname,
        last: lastname,
        email: email,
        mobile: mobile,
      });

      this.submitForm();
    },
    async submitForm() {
      // Splitting the time into start and end segments on the en dash.
      const [startTime, endTime] = this.time.split('–');
      const dueDate = this.formatDueDate(this.day, startTime);

      const formattedDealerId = formatDealerId(this.dealership.dealerId);

      await submitForScheduleTestDriveToELeadCRM(
        this.contact,
        endTime,
        startTime,
        dueDate,
        formattedDealerId,
        this.vehicle?.vin,
        this.setTestDriveView,
        (loading: boolean) => {
          this.loading = loading;
        },
        this.showError
      );

      let obj = {
        event: 'Test Drive Scheduled',
        Date: this.day,
        'Time Range': this.time,
        Store: formattedDealerId,
        'Test Drive VIN': this.vehicle.vin,
      };

      this.emitter.emit('analytics', obj);
    },
    showError(message: string) {
      console.error(message);
      alert(message);
    },
    mobileMask(e: InputEvent) {
      const input = e.target as HTMLInputElement;
      const rawValue = input.value.replace(/\D/g, '');
      const match = rawValue.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      if (!match) return;

      let formattedValue = '';

      if (match[1]) {
        formattedValue += `(${match[1]}`;
      }
      if (match[2]) {
        formattedValue += `) ${match[2]}`;
      }
      if (match[3]) {
        formattedValue += `-${match[3]}`;
      }

      input.value = formattedValue;

      this.mobile = formattedValue;
    },
    formatDueDate(date: string, time: string): string {
      const timeZone = this.dealership?.timeZone || 'US/Eastern';

      // time example input: "5 PM"
      let hourValue = parseInt(time);
      const minutePart = time.includes('PM') ? 'PM' : 'AM';

      if (minutePart === 'PM' && hourValue !== 12) {
        hourValue += 12;
      } else if (minutePart === 'AM' && hourValue === 12) {
        hourValue = 0;
      }

      const timeFormatted = `${String(hourValue).padStart(2, '0')}`;

      // Example format: 2017-05-15T08:30:00
      const appTimeIso =
        DateTime.fromISO(`${date}T${timeFormatted}:00`, { zone: timeZone }).toISO() ?? '';

      return appTimeIso;
    },
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;
@use 'sass:math';

$text-field-height: 68px;
$gap-between-fields: 8px;

.contact-info {
  padding: $spacing_stretch-sm;

  @include media-breakpoint-up(lg) {
    width: (500px - $spacing_inline-xxs - $spacing_inline-sm);
    padding: 0;
    margin: 0 $spacing_inline-xxs 0 $spacing_inline-sm;
  }
}

.header-info {
  margin-bottom: $spacing_stack-xxs;

  .h6 {
    margin-bottom: $spacing_stack-nano;
  }
}

.v-text-field {
  height: $text-field-height;
  margin-bottom: $gap-between-fields;

  .v-input__control {
    height: 100%;
  }

  .v-input__slot {
    height: 100%;
  }

  .v-messages__message {
    margin: 0;
  }
}

.book-button {
  margin: $spacing_stack-nano auto 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing_stack-xs;
  }

  .v-btn {
    width: 100%;
  }
  p {
    margin-top: $spacing_stack-xxxs;
    color: $park-gray-20;
    font-size: $font-size-xxxxs;
    line-height: $line-height-sm;
    letter-spacing: 0.2px;
    text-align: center;
  }
}

.v-messages__message {
  color: var(--supplementary-red-50) !important;
  margin-top: 4px !important;
}
</style>
