<template>
  <transition name="slide">
    <div class="make-model-trim" data-component-name="make-model-trim">
      <div v-show="!makeModelTrimFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="!!facetFilters[facetMetaData.mmt.key]"
          :title="$t('Make/Model/Trim')"
        />
      </div>
      <div v-show="makeModelTrimFilterSelected" class="mmt-body">
        <div class="v-row ma-0 py-4">
          <div v-if="!searchPhrase.length" class="v-col-7 py-0 px-6 d-flex crumb-container">
            <div
              id="makeTitle"
              :class="!makeSelected ? 'active-crumb' : ''"
              @click="selectMake()"
              :data-for="makeTitle"
            >
              {{ makeTitle }}
            </div>
            <div id="modelTitle" v-if="modelSelected || trimSelected" class="d-flex">
              <div class="px-1">/</div>
              <div
                :class="!modelSelected ? 'active-crumb ' : ''"
                @click="selectModel()"
                :data-for="modelTitle"
              >
                {{ modelTitle }}
              </div>
            </div>
            <div id="trimTitle" v-if="trimSelected" class="d-flex">
              <div class="px-1">/</div>
              <div
                :class="!trimSelected ? 'active-crumb' : ''"
                @click="selectTrim()"
                :data-for="trimTitle"
              >
                {{ trimTitle }}
              </div>
            </div>
          </div>
          <div v-else class="v-col-12 px-6 py-0 d-flex">
            {{ searchTitle }}
          </div>
        </div>

        <div class="mmt-search">
          <input
            class="search-input"
            v-model="searchPhrase"
            placeholder="Search make or model"
            @keyup="triggerDelayedSearch"
            type="text"
          />
          <label v-if="searchPhrase.length < 2">
            <img src="@icons/search.png" alt="Search Icon" />
          </label>
          <label
            v-else
            class="pointer"
            @click="resetSearchPhrase()"
            @keyup.enter="resetSearchPhrase()"
            tabindex="0"
          >
            <img src="@icons/close.png" class="" alt="close icon" />
          </label>
        </div>

        <transition name="slide">
          <div id="makeList" v-show="makeSelected" class="mmt-list">
            <HierarchicalFacetList
              class="facet-list"
              v-model="makesModel"
              :data="makes"
              :onSelect="loadModels"
              :sub-menu="'Model'"
            />
          </div>
        </transition>

        <transition name="slide">
          <div id="modelList" v-show="modelSelected" class="mmt-list">
            <HierarchicalFacetList
              class="facet-list"
              v-model="modelsModel"
              :data="models"
              :onSelect="loadTrims"
              :sub-menu="'Trim'"
              ref="modelsRef"
            />
          </div>
        </transition>

        <transition name="slide">
          <div id="trimList" v-show="trimSelected" class="mmt-list">
            <HierarchicalFacetList
              class="facet-list"
              v-model="trimsModel"
              :data="trims"
              ref="trimsRef"
            />
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import { mapMutations, mapState } from 'vuex';
import config from '@/appConfig';
import HierarchicalFacetList from './HierarchicalFacetList.vue';
import FilterMenuLineItem from './FilterMenuLineItem.vue';
import { facetMetaData } from '@util/facetHelper';
import {
  FacetMetaData,
  MMTFacetCountObject,
  MMTFilterSelection,
  MMTModel,
} from '@/types/VehicleSearch/Facets';
import { SrpVehiclesData } from '@/types/VehicleSearch/SearchResults';

export default defineComponent({
  name: 'MakeModelTrimFilter',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    HierarchicalFacetList,
    FilterMenuLineItem,
  },
  data() {
    return {
      makes: [] as MMTFilterSelection[],
      models: [] as MMTFilterSelection[],
      trims: [] as MMTFilterSelection[],
      mmtFacet: {},
      makeSelected: true,
      modelSelected: false,
      trimSelected: false,
      initialVehicleData: {} as SrpVehiclesData,
      expandedMake: '',
      expandedModel: '',
      searchPhrase: '',
      timeout: null as unknown as NodeJS.Timeout,
      mmtInteraction: false,
      searchInteraction: false,
      facetMetaData: facetMetaData,
      cachedCount: {},
      updateCachedCount: true,
      initializing: false,
    };
  },
  watch: {
    recentSelectedFilter(newValue, oldValue) {
      this.updateCachedCount = !(
        newValue === facetMetaData.mmt.key && oldValue !== facetMetaData.mmt.key
      );
    },
    mmtCount() {
      this.cachedCount = this.mmtCount;
      this.initialize(this.facetFilters);
    },
    async makeModelTrimFilterSelected(newValue, _oldValue) {
      if (newValue) {
        this.emitter.emit('fetch-facet-count', facetMetaData.mmt.key);
        this.selectMake();
        await this.initialize(this.facetFilters);
        this.searchPhrase = '';
      }
    },
    facetFilters: {
      handler: async function (newValue, _oldValue) {
        if (this.initializing) return;

        this.initializing = true;

        // Commented 13-Aug-24 temporarily because it was continuously firing during the vue3 upgrade
        // Uncomment as necessary
        // if (false && this.updateCachedCount && this.makeModelTrimFilterSelected) {
        //   this.emitter.emit('fetch-facet-count', facetMetaData.mmt.key);
        //   this.cachedCount = this.mmtCount;
        // }

        if (!this.mmtInteraction && !this.searchInteraction) {
          this.selectMake();
          await this.initialize(newValue);
          this.searchPhrase = '';
        }

        this.mmtInteraction = false;
        this.searchInteraction = false;
        this.initializing = false;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('searchResults', {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      makeModelTrimFilterSelected: (state) => state.makeModelTrimFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      mmtFacetData: (state) => state.facetCounts?.mmt,
      mmtCount: (state) => state.facetCounts?.mmt || {},
      recentSelectedFilter: (state) => state.recentSelectedFilter,
    }),
    initialMmtFacet() {
      return this.mmtFacetData ?? this.initialVehicleData?.facets[facetMetaData.mmt.key] ?? {};
    },
    makesModel: {
      get() {
        return this.makes;
      },
      set(data: MMTModel) {
        if (data.internalUpdate || this.searchPhrase) this.mmtInteraction = true;

        if (!data.internalUpdate) {
          this.mmtFacet = this.facetFilters[facetMetaData.mmt.key]?.facetValue ?? {};
        } else {
          this.updateMmtFacet(data.items, this.mmtFacet);
        }

        if (data.internalUpdate) {
          this.updateStore();
          let selectedMmt = this.facetFilters[facetMetaData.mmt.key].facetValue;
          this.parseSelectedMakeModels(selectedMmt);
        }
      },
    },
    modelsModel: {
      get() {
        return this.models;
      },
      set(data: MMTModel) {
        let expandedMake = data.items.map((x) => x.split('|')[0])[0] ?? this.expandedMake;
        let selectedItems = data.items.map((x) => x.split('|')[1]);

        if (!this.mmtFacet[expandedMake]) {
          this.expandedModel = expandedMake;
          this.expandedMake =
            Object.keys(this.mmtFacet).find((x) => {
              return this.mmtFacet[x].hasOwnProperty(this.expandedModel);
            }) || '';
        } else {
          this.expandedMake = expandedMake;
        }

        if (this.expandedModel && this.expandedMake) {
          this.updateMmtFacet(selectedItems, this.mmtFacet[this.expandedMake][this.expandedModel]);
        } else {
          this.updateMmtFacet(selectedItems, this.mmtFacet[this.expandedMake]);
        }

        if (data.internalUpdate) {
          this.updateStore();
          this.mmtInteraction = true;
        }
      },
    },
    trimsModel: {
      get() {
        return this.trims;
      },
      set(data: MMTModel) {
        this.mmtInteraction = true;
        let selectedItems = data.items.map((x) => x.split('|')[1]);
        this.updateMmtFacet(selectedItems, this.mmtFacet[this.expandedMake][this.expandedModel]);
        if (data.internalUpdate) {
          this.updateStore();
        }
      },
    },
    makeTitle() {
      return this.$t('Make');
    },
    modelTitle() {
      return this.$t('Model');
    },
    trimTitle() {
      return this.$t('Trim');
    },
    searchTitle() {
      return 'Searching make/model';
    },
    getMakeCount() {
      return (key) => {
        let cachedCount = 0;
        if (Object.keys(this.cachedCount).length !== 0) {
          if (this.cachedCount.hasOwnProperty(key)) {
            Object.values(this.cachedCount[key]).forEach((x) => {
              if ((x as MMTFacetCountObject).__count) {
                cachedCount += (x as MMTFacetCountObject).__count || 0;
              }
            });
          }
        }
        return cachedCount;
      };
    },
    getModelCount() {
      return (parent, make, key) => {
        let count = 0;
        if (Object.keys(this.cachedCount).length !== 0) {
          const makes =
            parent == null
              ? this.cachedCount && this.cachedCount[make]
              : this.cachedCount && this.cachedCount[parent] && this.cachedCount[parent][make];
          count = makes && makes.hasOwnProperty(key) ? makes[key].__count : 0;
        }

        return count;
      };
    },
    getTrimCount() {
      return (expandedMake, model, key) => {
        let count = 0;
        if (
          Object.keys(this.cachedCount).length !== 0 &&
          this.cachedCount &&
          this.cachedCount[expandedMake] &&
          this.cachedCount[expandedMake][model] &&
          this.cachedCount[expandedMake][model][key]
        ) {
          count = this.cachedCount[expandedMake][model][key].__count;
        }
        return count;
      };
    },
  },
  async created() {
    await this.initialize({} as FacetMetaData);
  },
  methods: {
    ...mapMutations('searchResults', [
      'setFilterSelected',
      'setMakeModelTrimFilterSelected',
      'setResetSelectedFacetItems',
      'setHierarchicalFacetFilter',
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setMakeModelTrimFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    async initialize(facetFilters: FacetMetaData) {
      if (!this.initialMmtFacet) {
        this.initialVehicleData = this.vehicleData;
      }
      if (facetFilters && facetFilters[facetMetaData.mmt.key] && facetFilters[facetMetaData.mmt.key]?.facetValue) {
        let selectedMmt = facetFilters[facetMetaData.mmt.key].facetValue;
        this.parseSelectedMakeModels(selectedMmt);

        this.makes = Object.keys(this.initialMmtFacet)
          .filter((x) => !this.containsNumbers(x))
          .map((key) => {
            // i don't think this code does anything
            // let count = 0;
            // Object.values(this.initialMmtFacet[key]).forEach((x) => {
            //   if (x.__count) {
            //     count += x.__count;
            //   }
            // });
            let isSelected = false;
            if (selectedMmt[key] && selectedMmt[key].selected) isSelected = true;
            return {
              name: key,
              fullName: key,
              value: this.getMakeCount(key),
              selected: isSelected,
            };
          });
      } else {
        if (!this.initialMmtFacet) {
          try {
            const response = await axios.get(config.vehicleApiEndpoint, {
              params: {},
            });
            this.initialVehicleData = response.data;
          } catch (error) {
            console.error(error);
          }
        }

        this.makes = Object.keys(this.initialMmtFacet)
          .filter((x) => !this.containsNumbers(x))
          .map((key) => {
            return {
              name: key,
              fullName: key,
              value: this.getMakeCount(key),
              selected: false,
            };
          });
      }
    },
    parseSelectedMakeModels(mmtFacetValue) {
      Object.keys(mmtFacetValue).forEach((x) => {
        if (x.includes('|')) {
          let make = x.split('|')[0];
          let model = x.split('|')[1];
          if (!Object.keys(mmtFacetValue).includes(make)) {
            // this.$set(mmtFacetValue, make, { selected: true }); // Remove after vue3 upgrade
            mmtFacetValue[make] = { selected: true };
          }
          if (!Object.keys(mmtFacetValue[make]).includes(model)) {
            // this.$set(mmtFacetValue[make], model, { selected: true }); // Remove after vue3 upgrade
            mmtFacetValue[make][model] = { selected: true };
            // this.$delete(mmtFacetValue, x); // Remove after vue3 upgrade
            delete mmtFacetValue[x];
          }
        }
      });
    },
    updateStore() {
      const data = {
        name: facetMetaData.mmt.key,
        value: this.mmtFacet,
      };

      this.setHierarchicalFacetFilter(data);
      this.emitter.emit('filter-updated-srp');
    },
    updateMmtFacet(selectedItems, target) {
      selectedItems.forEach((item) => {
        // if ((!target[item] || !target[item].selected)) { // vue3 note: added target truthy check as shown below

        if (target && (!target[item] || !target[item].selected)) {
          // this.$set(target, item, { selected: true }); // Remove after vue3 upgrade
          target[item] = { selected: true };
        }
      });

      for (let key in target) {
        if (key !== 'selected' && !selectedItems.includes(key)) {
          delete target[key];
        }
      }
    },
    async loadModels(make) {
      if (this.searchPhrase) {
        this.searchInteraction = true;
        this.mmtInteraction = true;
      }

      let selectedMmt = this.facetFilters[this.facetMetaData.mmt.key].facetValue;

      let parent = null as string | null;
      if (!Object.keys(this.initialMmtFacet).includes(make)) {
        Object.keys(this.initialMmtFacet).forEach((key) => {
          if (Object.keys(this.initialMmtFacet[key]).includes(make)) {
            parent = key;
            return;
          }
        });
      }

      const initialCollection =
        parent == null ? this.initialMmtFacet[make] : this.initialMmtFacet[parent][make];
      this.models = Object.keys(initialCollection)
        .filter((x) => x !== 'selected' && x !== '__count')
        .map((key) => {
          let isSelected = false;
          if (!parent) {
            if (selectedMmt[make][key] && selectedMmt[make][key].selected) isSelected = true;
          } else {
            if (
              selectedMmt[parent] &&
              selectedMmt[parent][make] &&
              selectedMmt[parent][make][key] &&
              selectedMmt[parent][make][key].selected
            )
              isSelected = true;
          }

          return {
            name: key,
            fullName: `${make}|${key}`,
            value: this.getModelCount(parent, make, key),
            selected: isSelected,
            lastChild: parent != null,
          };
        });

      this.selectModel();
      this.expandedMake = make;
    },
    async loadTrims(model) {
      let selectedMmt = this.facetFilters[facetMetaData.mmt.key].facetValue;
      this.trims = Object.keys(this.initialMmtFacet[this.expandedMake][model])
        .filter((x) => x !== 'selected' && x !== '__count')
        .map((key) => {
          let isSelected = false;
          if (
            selectedMmt[this.expandedMake][model][key] &&
            selectedMmt[this.expandedMake][model][key].selected
          )
            isSelected = true;

          return {
            name: key,
            fullName: `${model}|${key}`,
            value: this.getTrimCount(this.expandedMake, model, key),
            selected: isSelected,
          };
        });

      this.selectTrim();
      this.expandedModel = model;
    },
    selectMake() {
      this.makeSelected = true;
      this.modelSelected = false;
      this.trimSelected = false;
      this.expandedMake = '';
    },
    selectModel() {
      this.modelSelected = true;
      this.makeSelected = false;
      this.trimSelected = false;
      this.expandedModel = '';
    },
    selectTrim() {
      this.trimSelected = true;
      this.makeSelected = false;
      this.modelSelected = false;
    },
    containsNumbers(inputString) {
      const regex = /\d/;
      return regex.test(inputString);
    },
    triggerDelayedSearch(e) {
      let that = this;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(function () {
        that.selectMake();
        that.searchMakeModelTrim(e);
      }, 300);
    },
    searchMakeModelTrim(event) {
      const input = event.target.value.toLowerCase();

      const processFacet = (facet) => {
        const newMakesArray = [] as MMTFilterSelection[];

        Object.keys(this.initialMmtFacet)
          .filter((x) => !this.containsNumbers(x))
          .forEach((key) => {
            const makeCount = this.getCount(this.initialMmtFacet[key]);

            const isMakeSelected = facet[key] && facet[key].selected;
            const hideMake = !key.split(' ').some((y) => y.toLowerCase().startsWith(input));

            let makeAdded = 0;
            if (!hideMake) {
              newMakesArray.push({
                name: key,
                fullName: key,
                value: makeCount,
                selected: isMakeSelected,
                hidden: false,
              });
            } else {
              newMakesArray.push({
                name: key,
                fullName: key,
                value: makeCount,
                selected: isMakeSelected,
                hidden: true,
              });
            }

            Object.keys(this.initialMmtFacet[key])
              .filter((x) => x !== '__count')
              .forEach((subKey) => {
                const modelCount = this.getCount(this.initialMmtFacet[key][subKey]);
                const isModelSelected =
                  facet[key] && facet[key][subKey] && facet[key][subKey].selected;
                const hideModel = !subKey.split(' ').some((y) => y.toLowerCase().startsWith(input));

                if (!hideModel) {
                  if (makeAdded === 0) {
                    let parentMake = newMakesArray.find((x) => x.fullName === key);
                    if (parentMake) {
                      parentMake.hidden = false;
                      // this.$set(parentMake, 'parent', true); // Remove after vue3 upgrade
                      parentMake['parent'] = true;
                    }
                    makeAdded++;
                  }

                  newMakesArray.push({
                    name: subKey,
                    fullName: `${key}|${subKey}`,
                    value: modelCount,
                    selected: isModelSelected,
                    hidden: false,
                    subMenu: 'Trim',
                    descendant: true,
                  });
                }
              });
          });

        this.makes = newMakesArray;
      };

      if (input.length > 1) {
        const selectedMmt =
          this.facetFilters[facetMetaData.mmt.key] &&
          this.facetFilters[facetMetaData.mmt.key].facetValue;
        selectedMmt ? processFacet(selectedMmt) : processFacet({});
      } else {
        this.searchInteraction = false;
        this.mmtInteraction = false;
        this.initialize(this.facetFilters);
        this.selectMake();
      }
    },
    getCount(obj: MMTFacetCountObject) {
      let count = 0;

      Object.values(obj).forEach((x) => {
        if ((x as MMTFacetCountObject).__count) {
          count += (x as MMTFacetCountObject).__count;
        }
      });

      return count;
    },
    resetSearchPhrase() {
      this.searchPhrase = '';
      this.searchInteraction = false;
      this.mmtInteraction = false;
      this.initialize(this.facetFilters);
      this.selectMake();
    },
    resetVisibility(items) {
      items.forEach((x) => {
        // this.$set(x, 'hidden', false); // Remove after vue3 upgrade
        x['hidden'] = false;
      });
    },
  },
});
</script>

<style lang="scss">
.make-model-trim {
  .mmt-list {
    border-top: 1px solid #dee2e6 !important;
    margin-top: 1rem !important;
  }
  .selected-filter {
    font-weight: bold;
  }
  .pointer {
    cursor: pointer;
  }
  .active-crumb {
    color: #006fa6;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
  .crumb-container {
    cursor: default;
  }
  .mmt-search {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-top: 1px solid #e8e9eb;
    border-bottom: 1px solid #e8e9eb;
    background: #f4f5f7;

    label {
      display: flex;
    }
  }
  .search-input {
    flex: 1 0 0;
    color: #666b70;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: none;
    background: #f4f5f7;
  }
  .facet-list {
    width: 100%;
    max-height: calc(100vh - 215px);
  }
  .list-item {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
  }
  .mmt-body {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    height: 100%;
  }
  .slide-enter {
    transform: translateX(100%);
  }
  .slide-enter-active {
    transition: all 0.3s ease-in;
  }
  .slide-leave-active {
    transition: all 0.1s ease-in;
  }
  .slide-leave-to {
    transform: translateX(-100%);
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #d9d9d9 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 7px;
  }
  *::-webkit-scrollbar-track {
    background: #ffffff;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 8px;
    border: 1px solid #ffffff;
  }
  ::placeholder {
    color: #666b70;
    font-feature-settings:
      'clig' off,
      'liga' off;
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #666b70;
    font-feature-settings:
      'clig' off,
      'liga' off;
  }
}
</style>
