import {
  GraphQLDictionaryService,
  RestDictionaryService,
  constants,
} from '@sitecore-jss/sitecore-jss-vue';
import config from '@/appConfig';
const env = typeof process !== 'undefined' ? process.env : {};

function getApiHost(): string {
  const sitecoreApiHost: string = config.sitecoreApiHost;
  return sitecoreApiHost.length > 0 && sitecoreApiHost.endsWith('/')
    ? sitecoreApiHost.substring(0, sitecoreApiHost.length - 1)
    : sitecoreApiHost;
}

export class DictionaryServiceFactory {
  create() {
    return env.VUE_APP_FETCH_WITH === constants.FETCH_WITH.GRAPHQL
      ? new GraphQLDictionaryService({
          endpoint: config.graphQLEndpoint,
          apiKey: config.sitecoreApiKey,
          siteName: config.jssAppName,
          /*
            The Dictionary Service needs a root item ID in order to fetch dictionary phrases for the current
            app. If your Sitecore instance only has 1 JSS App, you can specify the root item ID here;
            otherwise, the service will attempt to figure out the root item for the current JSS App using GraphQL and app name.
            rootItemId: '{GUID}'
          */
        })
      : new RestDictionaryService({
          apiHost: getApiHost(),
          apiKey: config.sitecoreApiKey,
          siteName: config.jssAppName,
        });
  }
}

export const dictionaryServiceFactory = new DictionaryServiceFactory();
