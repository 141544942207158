<template>
  <CtaCarOffer data-testid="cta-banner" :isGrid="false" :fields="fields"></CtaCarOffer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CtaCarOffer from '@components/Feature/VehicleSearch/CtaCarOffer.vue';

export default defineComponent({
  name: 'CtaBanner',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    CtaCarOffer,
  },
});
</script>
