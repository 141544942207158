<template>
  <!-- 'router-view' is a "global" component that is injected into the Vue component registry by vue-router. -->
  <router-view />
  <div v-if="languageIsChanging" class="alert alert-info">{{ $t('Language is changing') }}...</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { dictionaryServiceFactory } from '@/lib/dictionary-service-factory';
import Cookies from 'js-cookie';

export default defineComponent({
  name: 'AppRoot',
  data() {
    return {
      languageIsChanging: false,
      contextViewVisible: false,
    };
  },
  methods: {
    changeAppLanguage(language: any) {
      const i18n: any = this.$i18n;
      if (i18n.locale !== language) {
        // Changing languages is an async action, therefore the `languageIsChanging` property can be used
        // to show a loading/switching screen when language is being changed.
        this.languageIsChanging = true;

        // create an instance of the dictionary service
        const dictionaryServiceInstance = dictionaryServiceFactory.create();

        dictionaryServiceInstance.fetchDictionaryData(language).then((phrases) => {
          i18n.setLocaleMessage(language, phrases);
          i18n.locale = language;

          this.languageIsChanging = false;
        });
      }
    },
    checkAudioEye() {
      let checkExist = setInterval(() => {
        let aeLauncher = document.getElementById('ae_launcher');
        if (aeLauncher) {
          let audioEyeIsEnabled = Cookies.get('audioEyeIsEnabled');
          if (audioEyeIsEnabled === 'true') {
            aeLauncher.style.display = 'block';
          } else {
            aeLauncher.remove();
          }

          clearInterval(checkExist);
        }
      }, 100);
    },
  },
  provide() {
    // Use Vue's provide/inject capabilities to "provide" functions to
    // any descendant component that want to use/"inject" the functions.
    return {
      languageIsChanging: this.languageIsChanging,
      changeAppLanguage: this.changeAppLanguage,
    };
  },
  mounted() {
    try {
      const optimizelyEl = document.createElement('script');
      optimizelyEl.setAttribute('src', '/-/media/Project/optimizelySnippet-20473601091.js');
      document.head.appendChild(optimizelyEl);
    } catch (error) {
      console.error('optimizely loader failed', error);
    }

    if (document.readyState !== 'loading') {
      this.checkAudioEye();
    } else {
      document.addEventListener('DOMContentLoaded', this.checkAudioEye);
    }
  },
});
</script>
