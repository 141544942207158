<template>
  <sc-rich-text
    v-if="supplementalContent"
    class="vlp-information-container"
    :field="supplementalContent"
  />
</template>

<script lang="ts">
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'VlpInformation',
  components: {
    ScRichText: RichText,
  },
  computed: {
    ...mapState('searchResults', ['supplementalContent']),
  },
});
</script>

<style lang="scss">
.vlp-information-container {
  order: 7;
  width: 100%;
  padding: 14px 24px 0 24px;
}
.vehicle-btm-content-heading {
  color: var(--park-gray);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  align-self: stretch;
  text-align: justify;
  margin-bottom: 24px;
}

.vehicle-btm-content p {
  color: var(--park-gray);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.vehicle-btm-section {
  margin-top: 40px;
}

.vehicle-btm-section [class*='col-'] {
  max-width: 100%;
  flex: 0 0 100%;
}

.vehicle-btm-content:not(:last-child) {
  margin-bottom: 24px;
}

.vehicle-btm-content p:not(:last-child) {
  margin-bottom: 16px;
}

.vehicle-btm-listitems:not(:last-child) {
  margin-bottom: 24px;
}

.vehicle-btm-listitems-heading {
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: var(--park-gray);
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .vehicle-btm-listitems-heading {
    margin-bottom: 32px;
  }
}

.vehicle-btm-listitems ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -16px;
}

.vehicle-btm-listitems ul li {
  width: 100%;
  min-width: 25%;
  max-width: 112px;
  margin-bottom: 16px;
  padding-right: 12px;
  list-style: none;
}

.vehicle-btm-listitems ul li a {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--supplementary-blue-60);
  text-decoration: none;
}
</style>
