<template>
  <div class="confirmation" v-if="view === 'confirmation'">
    <div class="confirmation-info">
      <p class="h5">{{ `You're all set, ${contact?.first}! 🎉` }}</p>
      <p data-testid="test-drive-confirmationHelperText">{{ fields.helperText.value }}</p>
      <div class="appointment-info">
        <div class="location-info">
          <img src="@images/location-icon.svg" :alt="dealership.storeName" />
          <div class="details">
            <a :href="getDirectionsLink()" target="_blank">{{
              formatDealerId(dealership?.dealerId)
            }}</a>
            <p>{{ getLocaleDay(day) }}</p>
            <p>{{ time }}</p>
          </div>
        </div>
        <ScheduleTestDriveVehicleInfo />
      </div>
      <div class="calendar-button">
        <v-btn :href="getCalendarHref()" download data-testid="test-drive-addToCalendarButton">{{
          fields.buttonText.value
        }}</v-btn>
      </div>
      <div class="value-props">
        <p data-testid="test-drive-valuePropsTitle" class="h6">
          {{ fields.valuePropsTitle.value }}
        </p>
        <p data-testid="test-drive-valuePropsHelperText" class="small">
          {{ fields.valuePropsHelperText.value }}
        </p>
        <div class="content">
          <div class="value-prop" v-for="prop in valueProps" :key="prop.title">
            <p class="sub-head"><v-icon :icon="prop.icon"></v-icon>{{ prop.title }}</p>
            <p class="value-prop-text">{{ prop.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="confirmation-footer">
      <div class="info">
        <p data-testid="test-drive-confirmationFooterTitle" class="h6">
          {{ fields.footerTitle.value }} {{ dealership.storeName }}
        </p>
        <p data-testid="test-drive-confirmationFooterText" class="small">
          {{ fields.footerText.value }}
        </p>
      </div>
      <v-btn
        class="secondary"
        @click="redirectToDealer"
        data-testid="test-drive-testDriveMoreButton"
      >
        {{ fields.footerButtonText.value }}
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatDealerId } from '@/util/scheduleTestDriveUtil';
</script>

<script lang="ts">
import { mapMutations, mapState } from 'vuex';
import { defineComponent } from 'vue';
import ScheduleTestDriveVehicleInfo from '@components/Page/ScheduleTestDrive/ScheduleTestDrive--VehicleInfo.vue';
import { DateTime } from 'luxon';

export default defineComponent({
  name: 'ScheduleTestDrive--Confirmation',
  components: { ScheduleTestDriveVehicleInfo },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    errorComponent: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('scheduleTestDrive', ['contact', 'day', 'time', 'view', 'vehicle', 'dealership']),
  },
  data() {
    return {
      valueProps: [
        {
          icon: 'ep-icon-car',
          title: 'Buying/Test driving',
          text: 'Driver’s license & insurance',
        },
        { icon: 'ep-icon-bank', title: 'Financing', text: 'Paycheck stub & utility bill' },
        {
          icon: 'ep-icon-cash',
          title: 'Selling/Trading',
          text: 'Photo ID, title/payoff & keys',
        },
      ],
    };
  },
  methods: {
    ...mapMutations('scheduleTestDrive', [
      'setTestDriveContact',
      'setTestDriveDay',
      'setTestDriveTime',
      'setTestDriveView',
    ]),
    getLocaleDay(day: string) {
      const normalizedDay = day.replaceAll('-', '/');
      const date = new Date(`${normalizedDay} GMT-12:00`); // we don't want timezone to be a factor in the simple date conversion
      return date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: undefined,
        month: 'long',
        day: 'numeric',
      });
    },
    getCalendarHref() {
      return `data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VTIMEZONE%0ATZID:UTC%0ABEGIN:STANDARD%0ADTSTART:16010101T000000%0ATZOFFSETFROM:-0000%0ATZOFFSETTO:-0000%0AEND:STANDARD%0AEND:VTIMEZONE%0ABEGIN:VEVENT%0ADTSTART;TZID=UTC:${this.getStartTime()}%0ADTEND;TZID=UTC:${this.getEndTime()}%0ASUMMARY:${this.fields.calendarTitle.value}%0ADESCRIPTION:${this.fields.calendarDescription.value}%0ALOCATION:${this.dealership.address}, ${this.dealership.city}, ${this.dealership.state} ${this.dealership.zip}%0AEND:VEVENT%0AEND:VCALENDAR`;
    },
    convertToUTC(day: string, time: string): { utcStartTime: string; utcEndTime: string } {
      // Splitting the time into start and end segments on the en dash.
      const [startSegment, endSegment] = time.split('–');

      const convertTime = (timeSegment, period) => {
        let [hour, minute = '00'] = timeSegment.trim().split(':');
        hour = parseInt(hour);

        if (period.toLowerCase() === 'pm' && hour !== 12) hour += 12;
        if (period.toLowerCase() === 'am' && hour === 12) hour = 0;

        const timeZone = this.dealership.timeZone;

        const utcTime =
          DateTime.fromISO(
            `${day}T${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`,
            {
              zone: timeZone,
            }
          ) ?? '';

        // Specific date format for ics
        return utcTime.toUTC().toFormat("yyyyMMdd'T'HHmmss");
      };

      const [startTime, startPeriod] = startSegment.trim().split(' ');
      const [endTime, endPeriod] = endSegment.trim().split(' ');

      const utcStartTime = convertTime(startTime, startPeriod);
      const utcEndTime = convertTime(endTime, endPeriod);

      return { utcStartTime, utcEndTime };
    },
    getStartTime() {
      const { utcStartTime } = this.convertToUTC(this.day, this.time);
      return utcStartTime;
    },
    getEndTime() {
      const { utcEndTime } = this.convertToUTC(this.day, this.time);
      return utcEndTime;
    },
    getDirectionsLink() {
      const address = `${this.dealership.address}, ${this.dealership.city}, ${this.dealership.state} ${this.dealership.zip}`;
      return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`;
    },
    redirectToDealer() {
      const baseUrl = window.location.href.split('?')[0];
      const queryParams = new URLSearchParams(window.location.search);

      queryParams.delete('radius');
      queryParams.set('ship', 'false');
      queryParams.set('dealer', this.dealership.dealerId.replace(/\s+/g, ''));

      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.open(newUrl, '_blank');
    },
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;
@use 'sass:math';

.confirmation-info {
  padding: $spacing_stack-xxs $spacing_inline-xxs $spacing_stack-xs;

  @include media-breakpoint-up(lg) {
    width: 100%;
    padding: $spacing_stack-xxs $spacing_inline-xl $spacing_stack-sm;
  }

  & > p {
    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }
  .h5 {
    margin-bottom: $spacing_stack-nano;
  }
}

.appointment-info {
  margin: $spacing_stack-xs 0;

  @include media-breakpoint-up(lg) {
    display: flex;
    gap: $spacing_inline-lg;
  }

  .vehicle-info {
    gap: $spacing_inline-xxxs;
    margin-top: $spacing_stack-xxs;

    @include media-breakpoint-up(lg) {
      width: 50%;
      margin-top: 0;
    }
  }
  .location-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $spacing_inline-xxxs;

    @include media-breakpoint-up(lg) {
      width: 50%;
      justify-content: flex-end;
    }

    img {
      width: 64px;
      height: 64px;
      border-radius: $border-radius-md;
      object-fit: cover;
    }
    a {
      font-weight: $font-weight-bold;
      text-decoration: none;
    }
    .details p {
      width: 100%;
    }
  }
}

.calendar-button {
  width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: 340px;
    margin: 0 auto;
  }

  .v-btn {
    width: 100%;
  }
  .note {
    font-size: $font-size-xxxxs;
    text-align: center;
    margin-top: $spacing_stack-xxxs;
  }
}

.value-props {
  margin-top: $spacing_stack-sm;

  .h6 {
    margin-bottom: $spacing_stack-nano;
  }
  .content {
    margin-top: $spacing_stack-xs;

    @include media-breakpoint-up(lg) {
      display: flex;
      gap: $spacing_inline-xs;
    }
  }
  .value-prop {
    margin-top: $spacing_stack-xxs;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }
  .sub-head {
    gap: $spacing_inline-nano;
    margin-bottom: $spacing_stack-quarck;
  }
  .v-icon {
    font-size: 24px;
  }
}

.confirmation-footer {
  background: $park-gray-100;
  padding: $spacing_stack-md $spacing_inline-xxs $spacing_stack-xxs;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    padding: $spacing_stack-sm $spacing_inline-xl;
  }

  .v-btn {
    margin-top: $spacing_stack-xs;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 220px;
      margin: 0 0 0 $spacing_inline-nano;
    }
  }
}
</style>
