<template>
  <div class="subtitle" data-testid="test-drive-subtitle">
    {{ view === 'confirmation' ? '' : fields.subtitle.value }}
  </div>
  <ScheduleTestDriveVehicleAndLocation :fields="fields" />
  <ScheduleTestDriveDayAndTime :fields="fields" />
  <ScheduleTestDriveContact :fields="fields" />
  <ScheduleTestDriveConfirmation :fields="fields" />
</template>

<script lang="ts">
import ScheduleTestDriveContact from '@components/Page/ScheduleTestDrive/ScheduleTestDrive--Contact.vue';
import ScheduleTestDriveDayAndTime from '@components/Page/ScheduleTestDrive/ScheduleTestDrive--DayAndTime.vue';
import ScheduleTestDriveVehicleAndLocation from '@components/Page/ScheduleTestDrive/ScheduleTestDrive--VehicleAndLocation.vue';
import { mapMutations, mapState } from 'vuex';
import ScheduleTestDriveConfirmation from '@components/Page/ScheduleTestDrive/ScheduleTestDrive--Confirmation.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ScheduleTestDrive',
  components: {
    ScheduleTestDriveConfirmation,
    ScheduleTestDriveContact,
    ScheduleTestDriveDayAndTime,
    ScheduleTestDriveVehicleAndLocation,
  },
  computed: {
    ...mapState('scheduleTestDrive', ['contact', 'day', 'time', 'view']),
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    errorComponent: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    view(_newView) {
      const modal = document.querySelector('.schedule-test-drive-modal .v-card');
      if (this.$vuetify.display.mdAndDown && modal) {
        modal.scrollTop = 0;
      }
    },
  },
  data() {
    return {
      title: 'Schedule test drive',
      subtitle:
        'This car will stay in our inventory & may sell before your test drive, so schedule your first ride soon!',
      buttonText: 'Test Drive',
    };
  },
  methods: {
    ...mapMutations('scheduleTestDrive', [
      'setTestDriveContact',
      'setTestDriveDay',
      'setTestDriveTime',
      'setTestDriveView',
      'setTestDriveVehicle',
      'setTestDriveDealership',
      'resetStoreDefaults',
    ]),
  },
  unmounted() {
    // clear data from vuex store
    // use delay so there isn't a flash of the day/time modal
    setTimeout(() => {
      this.resetStoreDefaults();
    }, 500);
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.schedule-test-drive-modal {
  height: 100%;

  .v-overlay__content {
    @include media-breakpoint-down(lg) {
      width: 100vw;
      max-width: 100vw;
      height: 100%;
      max-height: 100%;
      margin: 0;

      .v-card {
        border-radius: 0;
        width: 100vw;
        height: 100%;
      }
    }
    @include media-breakpoint-up(lg) {
      max-width: 800px;
      .v-card {
        border-radius: $border-radius-lg;
      }
    }
  }
  .subtitle {
    width: 100%;
    font-size: $font-size-xxxs;
    margin-top: -$spacing_stack-xxxs;
    margin-bottom: $spacing_stack-xxs;
    padding: 0 $spacing_inline-xxs;
    white-space: unset;
    color: $park-gray-0;
    font-weight: $font-weight-regular;
  }
  @include media-breakpoint-up(lg) {
    .v-card-text {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
