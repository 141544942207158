<template>
  <div class="vehicleCardPaper" :data-vin="vehicle.vin">
    <a :href="vehicle.url" @click="handleFullTileClick">
      <div class="vehicle-card-top">
        <FavoriteButton :favorite="isCarFavoriteData" @toggle="handleToggle" />
        <VehicleCardCarousel
          :imageList="imageList"
          :lowRezImage="vehicle.lowRezImage || imageList[0]"
          :imageAltText="`${vehicle.year} ${vehicle.make} ${vehicle.model}`"
          :seeMoreOnClick="handleFullTileClick"
        />
      </div>
      <div class="vehicleInfoWrapper">
        <VehicleCardDetails :vehicle="vehicle" />
        <div class="vehicleInfoWrapperPriceLocation">
          <VehicleCardPrice
            :vehicle="vehicle"
            :showPayEstimate="showPaymentEstimates"
            ref="vehicleCardPrice"
          />
          <VehicleCardLocation :shippingLabel="shippingTitle" :shippingStore="shippingDetails" />
        </div>
        <VehicleCardButtons :vehicle="vehicle" />
      </div>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import VehicleCardCarousel from './VehicleCardCarousel.vue';
import VehicleCardDetails from './VehicleCardDetails.vue';
import VehicleCardPrice from './VehicleCardPrice.vue';
import VehicleCardLocation from './VehicleCardLocation.vue';
import VehicleCardButtons from './VehicleCardButtons.vue';
import FavoriteButton from '@components-vue/Foundation/Common/Buttons/FavoriteButton.vue';
import favoriteService from '@services/favoriteService';
import { mapState } from 'vuex';
import {
  generateCapiEventId,
  getFbClientId,
  getFbClidFromUrl,
  generateUserDataSrp,
  generateCapiModelSrp,
  sendFacebookCapiEvent,
  sendTikTokCapiEvent,
} from '@assets/js/capiFunctions';
import { CapiModel } from '@/types/Analytics';
import { Vehicle } from '@/types/ServiceModels';

export default defineComponent({
  name: 'VehicleCard',
  data() {
    return {
      noImageSrc: require('@assets/images/no-image.svg'),
      isCarFavoriteData: false,
      shippableTitle: this.$t('CanShipCallOut'),
      shippableDetails: this.$t('ShippingStoreDetails'),
      pickUpTodayTitle: this.$t('PickupCallOut'),
      pickUpOnlyTitle: this.$t('PickUpOnlyAt'),
      pickUpDetails: this.$t('PickUpStoreDetails'),
    };
  },
  head() {
    if (this.preloadImage) {
      return {
        link: [{ rel: 'preload', href: this.vehicle.images[0], as: 'image' }],
      };
    }
  },
  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
      required: true,
    },
    showPaymentEstimates: Boolean,
    isCarFavorite: Boolean,
    preloadImage: Boolean,
  },
  components: {
    FavoriteButton,
    VehicleCardCarousel,
    VehicleCardDetails,
    VehicleCardPrice,
    VehicleCardLocation,
    VehicleCardButtons,
  },
  created() {
    this.isCarFavoriteData = this.isCarFavorite;
  },
  computed: {
    ...mapState('searchResults', ['dealerships']),
    imageList() {
      return !this.vehicle.images || this.vehicle.images.length < 1
        ? [this.noImageSrc]
        : this.vehicle.images;
    },
    shippingTitle() {
      if (this.vehicle.shippingStore) {
        return (this.shippableTitle ?? '').replace('##price##', String(this.vehicle.shippingPrice));

        //else if it's shippable > then display 'ship for cost to storename'
      } else if (this.vehicle.shippable) {
        return this.pickUpTodayTitle || '';
      } else {
        //else > pick up only at storename (mi)
        return this.pickUpOnlyTitle || '';
      }
    },
    shippingDetails() {
      if (this.vehicle.shippingStore) {
        return (
          this.shippableDetails?.replace(
            '##store##',
            this.getFormattedStoreName(this.vehicle.shippingStore)
          ) || ''
        );
        //else if it's shippable > then display 'ship for cost to storename'
      } else if (this.vehicle.shippable) {
        if (this.vehicle.distanceToUser) {
          return (this.pickUpDetails || '')
            .replace('##store##', this.getFormattedStoreName(this.vehicle.dealership))
            .replace(
              '##miles##',
              this.vehicle.distanceToUser.toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })
            );
        } else {
          //no distance to store
          return (this.shippableDetails || '').replace(
            '##store##',
            this.getFormattedStoreName(this.vehicle.dealership)
          );
        }
      } else if (this.vehicle.distanceToUser) {
        //else > pick up only at storename (mi)
        return (
          this.pickUpDetails
            ?.replace('##store##', this.getFormattedStoreName(this.vehicle.dealership))
            .replace('##miles##', String(this.vehicle.distanceToUser)) || ''
        );
      } else {
        //no distance to store
        return (this.shippableDetails || '').replace(
          '##store##',
          this.getFormattedStoreName(this.vehicle.dealership)
        );
      }
    },
  },
  methods: {
    /* This function is complicated because the vehicle tile is fully clickable
    AND there are elements on top of the tile that are also clickable (e.g. calculator) */
    handleFullTileClick(event) {
      if (event?.target?.closest('.v-carousel__controls')) {
        // when clicking the left-right arrows in the image carousel, don't trigger a VDP click
        event.preventDefault();
        event.stopPropagation();
      } else if (
        !event?.target?.closest('.ignoreFullTileClick') &&
        !event?.target?.closest('.carousel-control-next-icon') &&
        !event?.target?.closest('.carousel-control-prev-icon') &&
        !event?.target?.closest('.vehicleInfoDetailsStockNumber')
      ) {
        let capiEventIdVdp = generateCapiEventId('VDP Car Information');
        let obj = {
          event: 'VDP Car Information',
          make: this.vehicle.make || '',
          model: this.vehicle.model || '',
          year: this.vehicle.year || '',
          vin: this.vehicle.vin || '',
          Location: this.vehicle.dealership || '',
          Price: this.vehicle.sellingPrice || '',
          currency: 'USD',
          bodyType: '',
          // bodyType: this.vehicle.bodystyle || '',
          // TODO-vue3: this.vehicle.bodystle never existed...
          CAPI_event_id: capiEventIdVdp || '',
          // TODO-vue3: IsOutletVehicleFlag does not exist in the dom - should be removed from jq anyway
          // Cartype: document.getElementById('IsOutletVehicleFlag')?.value ? 'outlet' : 'normal',
          // TODO-vue3: HiddenHasCarFaxUrl also does not exist in the dom
          // Carfax_available:
          //   document.getElementById('HiddenHasCarFaxUrl')?.value === 'True' ? 1 : 0,
        };
        let contentCategory = 'VDP view';
        let contentName = 'VDP View';
        let eventName = 'ViewContent';
        let requestURL = window.location.href || '';
        let baseUrl = window.location.origin || '';
        let value = '0';
        let search = '';
        let street = '';
        let firstName = '';
        let lastName = '';
        let city = '';
        let state = '';
        let zipCode = document.getElementById('ZipcodeInformationHeader')?.innerText;
        let email = '';
        let phone = '';
        let userAgent = navigator.userAgent;
        let fbp = getFbClientId();
        let fbclid = getFbClidFromUrl();

        let userData = generateUserDataSrp(
          firstName,
          lastName,
          street,
          city,
          state,
          zipCode,
          email,
          phone,
          userAgent
        );
        let capiModel: CapiModel = generateCapiModelSrp(
          eventName,
          contentName,
          capiEventIdVdp,
          requestURL,
          baseUrl,
          this.vehicle.vin,
          userData,
          contentCategory,
          value,
          search,
          fbp,
          fbclid
        );

        sendFacebookCapiEvent(capiModel);
        sendTikTokCapiEvent(capiModel, 'ViewContent');
        this.emitter.emit('analytics', obj);

        if (this.vehicle.url) {
          window.location.href = this.vehicle.url;
        }
      }
    },

    getFormattedStoreName(dealership) {
      if (typeof dealership !== 'undefined' || dealership !== null) {
        if (this.dealerships != null) {
          for (let i = 0; i < this.dealerships.length; i++) {
            if (this.dealerships[i].dealerId == dealership) {
              return this.dealerships[i].storeName ?? this.formatStoreName(dealership);
            }
          }
        }
      }
      return this.formatStoreName(dealership);
    },

    formatStoreName(storeName: string) {
      //turn DealerID into a name if the store, for some reason the store is Undefined
      let epStringRegEx = /EchoPark/;

      if (storeName === null || typeof storeName === 'undefined') return 'the store is closed';

      let matched = epStringRegEx.exec(storeName); //try to find "EchoPark" in for example: EchoParkRaleigh
      if (matched) {
        const indexAfter = matched.index + matched[0].length; //separate EchoPark out from EchoParkRaleighAndOtherStuff
        const output = storeName.substring(indexAfter).replace(/([A-Z])/g, ' $1'); //add a space between each capital, starting after "EchoPark"
        return output.trim();
      } else {
        return storeName;
      }
    },

    async handleToggle() {
      if (!this.vehicle) return;

      try {
        let response = await favoriteService.toggleFavorite(this.vehicle.vin);
        if (response && response['Data']) {
          this.isCarFavoriteData = response['Data']['IsFavorite'];
        }
      } catch (error) {
        console.error(error);
      }

      if (this.isCarFavoriteData) {
        let capiEventId = generateCapiEventId('favorite applied');
        let contentCategory = 'Car Added to Favorites';
        let requestURL = window.location.href;
        let vin = this.vehicle.vin || '';
        let value = '0';
        let search = '';
        let baseUrl = window.location.origin;
        let street = '';
        let firstName = '';
        let lastName = '';
        let city = '';
        let state = '';
        let zipCode = document.getElementById('ZipcodeInformationHeader')?.innerText;
        let email = '';
        let phone = '';
        let userAgent = navigator.userAgent;
        let fbp = getFbClientId();
        let fbclid = getFbClidFromUrl();
        let obj = {
          CAPI_event_id: capiEventId,
          PageLocation: document.getElementById('SrpPaginationText')?.innerText.trim(),
          event: 'favorite applied',
          make: this.vehicle.model || '',
          model: this.vehicle.make || '',
          vin: this.vehicle.vin || '',
          year: this.vehicle.year || '',
        };
        this.emitter.emit('analytics', obj);
        let userData = generateUserDataSrp(
          firstName,
          lastName,
          street,
          city,
          state,
          zipCode,
          email,
          phone,
          userAgent
        );
        let capiModel = generateCapiModelSrp(
          'AddToWishlist',
          'SRP2',
          capiEventId,
          requestURL,
          baseUrl,
          vin,
          userData,
          contentCategory,
          value,
          search,
          fbp,
          fbclid
        );
        sendFacebookCapiEvent(capiModel);
        sendTikTokCapiEvent(capiModel, 'AddToWishlist');
      }
    },
  },
});
</script>

<style lang="scss">
.vehicleCardPaper {
  border-radius: 16px;
  border: solid 1px var(--shadow-white);
  background-color: var(--base-white-100);
  overflow: hidden;
  min-width: 280px;
  max-width: 420px;
  width: 100%;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }

  & > a {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
    border-radius: 16px;

    &:hover {
      text-decoration: none;
    }

    &:focus-visible {
      outline-offset: -3px;
    }
  }
}

.vehicleInfoWrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vehicleInfoWrapperPriceLocation {
  display: flex;
  margin-bottom: 14px;
  margin-top: 14px;
}
</style>
