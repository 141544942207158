import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chat-box chat-pos" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_image = _resolveComponent("sc-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      role: "button",
      tabindex: "0",
      class: "cn-b13-btn cn-b13-chat",
      "data-name": "Chat Icon Button",
      title: "Chat",
      "aria-label": "Chat",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleChat && _ctx.toggleChat(...args)))
    }, [
      _createVNode(_component_sc_image, {
        media: _ctx.fields.ChatIconImage,
        height: "48",
        width: "48"
      }, null, 8, ["media"])
    ]),
    _createElementVNode("button", {
      type: "button",
      role: "button",
      tabindex: "0",
      class: "cn-b13-btn cn-b13-hide cn-b13-x cn-b13-x-chat",
      title: "Close",
      "aria-label": "Close",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleChat && _ctx.toggleChat(...args)))
    }, [
      _createVNode(_component_sc_image, {
        media: _ctx.fields.ChatCloseIconImage
      }, null, 8, ["media"])
    ])
  ]))
}