import { Module } from 'vuex';
import { RootState } from '../State';
import { facetMetaData } from '@util/facetHelper';
import { generateCapiEventId } from '@assets/js/capiFunctions';
import Cookies from 'js-cookie';

import emitter from '@util/eventBus';

import { SearchResultsState, SortType } from '@/types/VehicleSearch/SearchResults';
import { FacetFilters } from '@/types/VehicleSearch/Facets';

/* Default State */
const state = (): SearchResultsState => ({
  queryParams: {
    page: 0,
  },
  loading: false,
  filterSelected: false,
  distanceShippingFilterSelected: false,
  mpgEngineFilterSelected: false,
  colorFilterSelected: false,
  bodyStyleFilterSelected: false,
  yearFilterSelected: false,
  mileageFilterSelected: false,
  driveTypeFilterSelected: false,
  priceFilterSelected: false,
  makeModelTrimFilterSelected: false,
  extColorToggleSelected: true,
  priceToggleSelected: true,
  shipToStore: true,
  shipToStoreRadius: true,
  shopByStore: false,
  filterZipFlyout: false,
  featureFilterSelected: false,
  radius: -1,
  defaultRadius: -1,
  take: 24,
  srpVehiclesData: null,
  includedDealerships: [],
  facetFilters: {} as FacetFilters,
  selectedFacetFilters: {} as FacetFilters,
  resetSelectedFacetItems: false,
  sortType: {
    sortBy: 'distance',
    sortDirection: 'asc',
    sortName: 'Distance: Nearest',
  },
  askUsModalTitle: null,
  facetChipsExpanded: false,
  hiddenChips: 0,
  facetCounts: {},
  recentSelectedFilter: '',
  remainingParams: {},
  supplementalContent: {},
});

export const SearchResultsModule = (): Module<SearchResultsState, RootState> => {
  // function to recursively get object keys and filter "selected" out
  const getKeys = function (obj) {
    return Object.keys(obj)
      .flatMap((k) => (Object(obj[k]) === obj[k] ? [k, ...getKeys(obj[k])] : k))
      .filter((item) => item !== 'selected');
  };

  const setShopLocationCookie = function (obj: object) {
    if (Cookies.set) {
      if (obj['radius']) {
        Cookies.set('ep_shopLocation', JSON.stringify({ radius: obj['radius'] }), {
          path: '/used-cars',
        });
      } else if (isNaN(obj['radius']) && typeof obj['radius'] !== 'undefined') {
        Cookies.set('ep_shopLocation', JSON.stringify({ radius: -1 }), { path: '/used-cars' });
      } else if (obj.hasOwnProperty('dealer') && obj['dealer']) {
        Cookies.set('ep_shopLocation', JSON.stringify({ dealers: obj['dealer'] }), {
          path: '/used-cars',
        });
      }
    }
  };

  return {
    namespaced: true,
    state,
    mutations: {
      setQueryParams(state, paramObject) {
        if (paramObject?.page) {
          state.queryParams['page'] = paramObject?.page;
        }
      },
      setPage(state, value) {
        state.queryParams['page'] = parseInt(value);
      },
      setAskUsModalTitle(state, value) {
        state.askUsModalTitle = value;
      },
      setPageIncrement(state, value) {
        state.queryParams['page'] = parseInt(state.queryParams['page']) + parseInt(value);
      },
      setFeatureFilterSelected(state, value) {
        state.featureFilterSelected = value;
      },
      setFilterSelected(state, value) {
        if (!value) {
          state.distanceShippingFilterSelected = value;
          state.mpgEngineFilterSelected = value;
          state.colorFilterSelected = value;
          state.bodyStyleFilterSelected = value;
          state.yearFilterSelected = value;
          state.mileageFilterSelected = value;
          state.driveTypeFilterSelected = value;
          state.priceFilterSelected = value;
          state.makeModelTrimFilterSelected = value;
          state.featureFilterSelected = value;
        }
        state.filterSelected = value;
      },
      setSpecificFilterSelected(state, value) {
        const filter = value;

        if (value && value.length > 0) {
          state.filterSelected = true;
          // TODO add better types for facetMetaData here
          if (filter === 'storename' || filter === 'shiptostore') {
            state.distanceShippingFilterSelected = true;
          } else if (filter === facetMetaData['mmt'].key) {
            state.makeModelTrimFilterSelected = true;
          } else if (filter === facetMetaData['bodystyle'].key) {
            state.bodyStyleFilterSelected = true;
          } else if (filter === facetMetaData['price'].key) {
            state.priceFilterSelected = true;
            state.priceToggleSelected = true;
          } else if (filter === facetMetaData['payment'].key) {
            state.priceFilterSelected = true;
            state.priceToggleSelected = false;
          } else if (filter === facetMetaData['miles'].key) {
            state.mileageFilterSelected = true;
          } else if (filter === facetMetaData['year'].key) {
            state.yearFilterSelected = true;
          } else if (filter === facetMetaData['feature'].key) {
            state.featureFilterSelected = true;
          } else if (
            filter === facetMetaData['hmpg'] ||
            filter === facetMetaData['enginedescription'].key
          ) {
            state.mpgEngineFilterSelected = true;
          } else if (
            filter === facetMetaData['drivetype'].key ||
            filter === facetMetaData['transmission'].key
          ) {
            state.driveTypeFilterSelected = true;
          } else if (filter === facetMetaData['extcolor'].key) {
            state.colorFilterSelected = true;
            state.extColorToggleSelected = true;
          } else if (filter === facetMetaData['intcolor'].key) {
            state.colorFilterSelected = true;
            state.extColorToggleSelected = false;
          }
        }
      },
      setDistanceShippingFilterSelected(state, value) {
        state.distanceShippingFilterSelected = value;
      },
      setMakeModelTrimFilterSelected(state, value) {
        state.makeModelTrimFilterSelected = value;
      },
      setColorFilterSelected(state, value) {
        state.colorFilterSelected = value;
      },
      setMpgEngineFilterSelected(state, value) {
        state.mpgEngineFilterSelected = value;
      },
      setBodyStyleFilterSelected(state, value) {
        state.bodyStyleFilterSelected = value;
      },
      setYearFilterSelected(state, value) {
        state.yearFilterSelected = value;
      },
      setMileageFilterSelected(state, value) {
        state.mileageFilterSelected = value;
      },
      setDriveTypeFilterSelected(state, value) {
        state.driveTypeFilterSelected = value;
      },
      setPriceFilterSelected(state, value) {
        state.priceFilterSelected = value;
      },
      setShopByStore(state, value) {
        state.shopByStore = value;
        setShopLocationCookie({ radius: state.radius });
      },
      setFilterZipFlyout(state, value) {
        state.filterZipFlyout = value;
      },
      setRecentSelectedFilter(state, value) {
        state.recentSelectedFilter = value;
      },
      setShipToStore(state, value) {
        state.shipToStore = value;

        let obj = {
          Removed: `Ship To Store`,
          event: '',
        };

        if (value) {
          obj = {
            Removed: `Ship To Store`,
            event: 'Filter Applied',
          };
        } else if (value) {
          obj = {
            Removed: `Ship To Store`,
            event: 'Filter Removed',
          };
        }

        emitter.emit('analytics', obj);
      },
      setShipToStoreRadius(state, value) {
        state.shipToStoreRadius = value;
      },
      initializeRadius(state, value) {
        if (!value || state.radius === value) return;
        state.radius = value;
      },
      setRadius(state, value) {
        state.radius = value;
        setShopLocationCookie({ radius: state.radius });
      },
      setTake(state, value) {
        state.take = value;
      },
      setSrpVehiclesData(state, value) {
        state.srpVehiclesData = value || 0;
        const facetKeys = Object.keys(state.facetFilters);
        const facetValues = Object.values(state.facetFilters);
        const appliedFacets: string[] = [];
        const includedDealershipsString = Object.values(state.includedDealerships);

        facetValues.forEach((nestedObj, index) => {
          if (nestedObj?.type) {
            if (
              nestedObj.type === 'numericRange' &&
              nestedObj.min !== undefined &&
              nestedObj.max !== undefined
            ) {
              appliedFacets.push(`${facetKeys[index]}: ${nestedObj['min']}-${nestedObj.max}`);
            } else if (nestedObj.type === 'string' && nestedObj.facetValue) {
              appliedFacets.push(`${facetKeys[index]}:${nestedObj.facetValue}`);
            } else if (nestedObj.type === 'hierarchical' && nestedObj.facetValue) {
              appliedFacets.push(`${facetKeys[index]}:${getKeys(nestedObj.facetValue)}`);
            }
          }
        });

        appliedFacets.push(...includedDealershipsString);

        const appliedFacetsFormatted = appliedFacets.join(', ');

        const viewResultsObj = {
          applied: appliedFacetsFormatted ?? '',
          event: 'View Results Clicked',
          // nearestStore: state.nearestDealership?.dealerId ?? '', // TODO grab from common
          resultCount: state.srpVehiclesData?.resultCount ?? 0,
        };

        emitter.emit('analytics', viewResultsObj);

        const capiEventId = generateCapiEventId('top cars Information');
        const obj = {
          event: 'top cars Information',
          CAPI_event_id: capiEventId,
        };
        let locationName, makeName, priceName, modelName, currencyName, vinName, yearName;
        for (let i = 0; i < 5; i++) {
          locationName = `Location${i + 1}`;
          makeName = `make${i + 1}`;
          priceName = `Price${i + 1}`;
          modelName = `model${i + 1}`;
          currencyName = `currency${i + 1}`;
          vinName = `vin${i + 1}`;
          yearName = `year${i + 1}`;
          // zipName = `zip${i + 1}`;

          obj[locationName] = value.items[i]?.dealership || null;
          obj[makeName] = value.items[i]?.make || null;
          obj[priceName] = value.items[i]?.sellingPrice || null;
          obj[modelName] = value.items[i]?.model || null;
          obj[currencyName] = 'USD';
          obj[vinName] = value.items[i]?.vin || null;
          obj[yearName] = value.items[i]?.year || null;
          // obj[zipName] = state.rootState.common.zipCode || null; // TODO-vue3 [PETER] -- rip from common
        }
        emitter.emit('analytics', obj);
      },
      setLoading(state, value) {
        state.loading = value;
      },
      setIncludedDealerships(state, value) {
        state.includedDealerships = value ?? [];
        if (state.includedDealerships.length > 0) {
          state.shopByStore = true;
          state.shipToStore = false;
          setShopLocationCookie({ dealer: state.includedDealerships });
        } else {
          state.shopByStore = false; // if 0 selected stores, cannot shop by store
          state.shipToStore = state.shipToStoreRadius;
        }
      },
      setFacetCounts(state, value) {
        state.facetCounts = value;
      },
      setResetSelectedFacetItems(state, value) {
        state.resetSelectedFacetItems = value;
      },
      setDefaultRadius(state, value) {
        state.defaultRadius = value;
      },
      setRemoveFacet: function (state, value) {
        const facetFilters = state.facetFilters[value];
        const facetFiltersValues = Object.values(facetFilters);
        let obj = {
          Removed: `${value}`,
          event: 'Filter Removed',
        };
        if (
          typeof facetFiltersValues[2] != undefined &&
          facetFiltersValues[2] != null &&
          facetFiltersValues[2] == 'numericRange'
        ) {
          obj = {
            Removed: `${value}:${facetFiltersValues[0]}-${facetFiltersValues[1]}`,
            event: 'Filter Removed',
          };
        }

        delete state.facetFilters[value];
        emitter.emit('analytics', obj);
      },
      setStringFacetFilter: function (state, value) {
        const facetName = value['name'];
        const facetValue = value['value'];

        if (typeof facetValue === undefined || facetValue === null) {
          // Vue.set(state.facetFilters, facetName, undefined);
          state.facetFilters[facetName] = undefined;
        } else {
          //Vue.set(state.facetFilters, facetName, { facetValue, type: 'string' });
          state.facetFilters[facetName] = { facetValue, type: 'string' };
          emitter.emit('analytics', {
            Applied: `${facetName}:${facetValue}`,
            event: 'Filter Applied',
          });
        }
      },
      setSelectedFacetItems: function (state, value) {
        const facetName = value['name'];
        const facetValue = value['value'];

        if (typeof facetValue === undefined || facetValue === null) {
          // Vue.set(state.selectedFacetFilters, facetName, undefined); // remove after the vue3 upgrade
          state.selectedFacetFilters[facetName] = undefined;
        } else {
          // Vue.set(state.selectedFacetFilters, facetName, facetValue); // remove after the vue3 upgrade
          state.selectedFacetFilters[facetName] = facetValue;
        }
      },
      setHierarchicalFacetFilter: function (state, value) {
        const facetName = value['name'];
        const facetValue = value['value'];

        // Create a new object to replace the specific facet filter
        const newFacetFilter = {};

        if (typeof facetValue === undefined || facetValue === null) {
          newFacetFilter[facetName] = undefined;
        } else {
          newFacetFilter[facetName] = { facetValue, type: 'hierarchical' };

          const filterValue = getKeys(facetValue);
          const obj = {
            Applied: `${facetName}:${filterValue}`,
            event: 'Filter Applied',
          };
          emitter.emit('analytics', obj);
        }

        // Replace the facet filter for the specific facet name
        state.facetFilters = { ...state.facetFilters, ...newFacetFilter };
      },
      setNumericFacetFilter: function (state, value) {
        const facetName = value['name'];
        const facetValue = value['value'];

        if (typeof facetValue === undefined || facetValue === null) {
          // Vue.set(state.facetFilters, facetName, undefined); // vue3 upgrade: see Vue.set note above
          state.facetFilters[facetName] = undefined;
        } else {
          // Vue.set(state.facetFilters, facetName, { ...facetValue, type: 'numericRange' }); // vue3 upgrade: see Vue.set note above
          state.facetFilters[facetName] = { ...facetValue, type: 'numericRange' };
          const obj = {
            Applied: `${facetName}:${facetValue.min} - ${facetValue.max}`,
            event: 'Filter Applied',
          };
          emitter.emit('analytics', obj);
        }
      },
      setSortType(state, value: SortType) {
        state.sortType = value;
      },
      setResetSelectedFacetItemList(state, value) {
        const filterType = value;

        if (state.facetFilters[filterType]) {
          let excludedVals: any[] = [];
          if (state.selectedFacetFilters[filterType]) {
            excludedVals = state.selectedFacetFilters[filterType];
          }

          state.facetFilters[filterType].facetValue.forEach((facetValue: any) => {
            if (!state.selectedFacetFilters[filterType]) {
              // Vue.set(state.selectedFacetFilters, filterType, [facetValue]);
              state.selectedFacetFilters[filterType] = [facetValue];
            } else if (
              state.selectedFacetFilters[filterType] &&
              !state.selectedFacetFilters[filterType].hasOwnProperty(facetValue) &&
              !excludedVals.includes(facetValue) // @ts-ignore
            ) {
              const val = [facetValue];
              const includedVals = state.selectedFacetFilters[filterType];
              // Vue.set(state.selectedFacetFilters, filterType, val.concat(includedVals));
              state.selectedFacetFilters[filterType] = val.concat(includedVals);
            }
          });
        }
      },
      clearSelectedFacets(state) {
        Object.keys(state.facetFilters).forEach((key) => {
          delete state.facetFilters[key];
        });

        Object.keys(state.selectedFacetFilters).forEach((key) => {
          delete state.selectedFacetFilters[key];
        });
      },
      resetStoreDefaults(state, layoutPath) {
        if (!layoutPath) {
          state.includedDealerships = [];
        }
        state.queryParams['page'] = 0;
        state.shipToStore = true;
        state.shopByStore = false;
        state.filterZipFlyout = false;
        state.radius = state.defaultRadius ?? -1;
        state.take = 24;
        state.sortType = {
          sortBy: 'distance',
          sortDirection: 'asc',
          sortName: 'Distance: Nearest',
        };

        state.facetChipsExpanded = false;
        state.hiddenChips = 0;
        state.recentSelectedFilter = '';
        setShopLocationCookie({ radius: state.radius });
      },
      setFacetChipsExpanded(state, value) {
        state.facetChipsExpanded = value;
      },
      setHiddenChipCount(state, value) {
        state.hiddenChips = value;
      },
      setExtColorToggleSelected(state, value) {
        state.extColorToggleSelected = value;
      },
      setPriceToggleSelected(state, value) {
        state.priceToggleSelected = value;
      },
      setFacetFilters(state, value) {
        const newFacetFilters = {};
        if (value) {
          for (const propertyName in value) {
            if (value[propertyName]) {
              newFacetFilters[propertyName] = value[propertyName];
            }
          }
        }
        state.facetFilters = newFacetFilters;
      },
      setRemainingParams(state, value) {
        const newRemainingParams = {};
        if (value) {
          for (const key in value) {
            if (value[key]) {
              newRemainingParams[key] = value[key];
            }
          }
        }
        state.remainingParams = newRemainingParams;
      },
      setSupplementalContent(state, value) {
        state.supplementalContent = value;
      },
    },
    actions: {},
    getters: {
      formattedVehicleAmount(state) {
        return (state.srpVehiclesData?.resultCount ?? 0).toLocaleString().replace('.', ',');
      },
      getVehicleByVin: (state) => (selectedVin: string) => {
        return state.srpVehiclesData?.items.find((vehicle) => vehicle?.vin === selectedVin);
      },
      getDynamicLinkData(state) {
        return state.srpVehiclesData?.dynamicLinks;
      },
      vehicleCount(state) {
        return state.srpVehiclesData?.resultCount ?? 0;
      },
      metaDataContent(state) {
        if (Object.keys(state.facetFilters).length > 0) {
          const relevantFilters = ['extcolor', 'year', 'mmt', 'bodystyle'];
          const allFilternames = Object.keys(state.facetFilters);
          const filtersICareAbout = allFilternames.filter((name) => relevantFilters.includes(name));
          //calculate how many total selected filters(facets) there are (that we care about [color, make, model, body style, year])
          const selectedFiltersCount = filtersICareAbout.reduce((counter, current) => {
            //count up how many filters
            switch (current) {
              case 'extcolor':
              case 'bodystyle':
                return counter + state.facetFilters[current].facetValue.length;
              case 'mmt':
                const makes = Object.keys(state.facetFilters[current].facetValue);
                const makesCount = makes.length;

                const modelsCountTotal = makes.reduce((modelsTotal, make) => {
                  const models = Object.keys(state.facetFilters[current].facetValue[make]).filter(
                    (model) => model !== 'selected'
                  );
                  const modelsCount = models.length;
                  return modelsTotal + modelsCount;
                }, 0);

                return counter + makesCount + modelsCountTotal;
              case 'year':
                return counter + 2;
              default:
                return counter;
            }
          }, 0);

          //logic for adjusting the title for the specified criteria: https://sonicautomotive.atlassian.net/browse/SAECHO-4788
          if (selectedFiltersCount > 3 || selectedFiltersCount < 1) {
            return null;
          }

          //if less than 3 filters, set the title in priority of facets: Color, year, make, model, body style
          let contentArr: any[] = [];
          if (state.facetFilters['extcolor']) {
            contentArr = [...state.facetFilters['extcolor'].facetValue];
          }
          if (state.facetFilters['year']) {
            contentArr = [
              ...contentArr,
              state.facetFilters['year'].min,
              '-',
              state.facetFilters['year'].max,
            ]; //spread + append new
          }
          if (state.facetFilters['mmt']) {
            //iterate over makes for each model
            const makes = Object.keys(state.facetFilters['mmt'].facetValue);
            makes.forEach((make) => {
              const models = Object.keys(state.facetFilters['mmt'].facetValue[make]).filter(
                (model) => model !== 'selected' //because dataStructure is: {make {model1, model2, selected {true/false} } }
              );
              contentArr = [...contentArr, make, ...models];
            });
          }
          if (state.facetFilters['bodystyle']) {
            contentArr = [...contentArr, ...state.facetFilters['bodystyle'].facetValue];
          }
          return contentArr.join(' ');
        }
      },
      metaDataLocation(state, _getters, rootState) {
        if (!state.shopByStore || state.includedDealerships.length != 1) {
          return null;
        }
        const dealerId = state.includedDealerships[0];
        const dealer = rootState['common'].dealerships.find((d) => d.dealerId == dealerId);
        return dealer?.storeName ?? null;
      },
    },
  };
};
